import {
    useGet,
    Table,
    TableBodyRow,
    Td,
    H2,
} from "@collabodoc/component-library";
import {API_URLS} from "../../URLS";
import {Col, Card} from "react-bootstrap";
import {useState} from "react";
import styled from "styled-components";
import {itemTypes} from "../../enums";
import symptomTypeStatusGroups from "./SymptomTypeStatusGroups";

const SearchBar = styled.input`
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px gray;
    border-radius: 3px;
    padding: 5px;
    outline: none;
`;

const StyledCard = styled(Card)`
    margin-top: 15px;
`;

const AddStatusGroupToSymptomForm = ({handleAddStatusGroup, symptomtypeStatusGroups}) => {
    const {response: statusGroups = []} = useGet(
        API_URLS.STATUSGROUPS,
        "",
        true,
        {"cache-control": "no-cache"});
    const [searchText, setSearchText] = useState("");

    const noDuplicateItemList = statusGroups.filter((item) => {
        return (
            symptomtypeStatusGroups &&
            !symptomtypeStatusGroups
                .map((q) => q.id)
                .includes(item.id)
        );
    });
    
    const filteredItems = noDuplicateItemList.filter((item) => {
        return (
            item.statusGroupName &&
            (item.statusGroupName.toLowerCase().includes(searchText.toLowerCase()) ||
                String(item.id).includes(searchText))
        );
    });
    
    const sortedItems = filteredItems.sort((a, b) => {
        return b.phrase < a.phrase ? 1 : b.phrase > a.phrase ? -1 : 0;
    });

    return (
        <Col lg={{span: 12}}>
            <StyledCard>
                <Card.Header>
                    <H2>Lägg till statusmall</H2>
                </Card.Header>
                <Card.Body>
                    <SearchBar
                        placeholder={"Sök efter namn eller id..."}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Table>
                        <tbody>
                        {sortedItems.length < 1 && searchText.length > 0 ? (
                            <TableBodyRow>
                                <Td>Inga matchande statusmallar</Td>
                            </TableBodyRow>
                        ) : (
                            sortedItems.map((item) => (
                                <TableBodyRow
                                    onClick={() =>
                                        handleAddStatusGroup(item.id)
                                    }
                                    key={item.id}
                                >
                                    <Td>Id:&nbsp;({item.id})</Td>
                                    <Td>{item.statusGroupName}</Td>
                                </TableBodyRow>
                            ))
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
            </StyledCard>
        </Col>
    );
};

export default AddStatusGroupToSymptomForm;
