import {
  useGet,
  Table,
  TableBodyRow,
  Td,
  H2,
} from "@collabodoc/component-library";
import { API_URLS } from "../../URLS";
import { Col, Card } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { itemTypes } from "../../enums";

const SearchBar = styled.input`
  width: 100%;
  margin-bottom: 10px;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 5px;
  outline: none;
`;

const StyledCard = styled(Card)`
  margin-top: 15px;
`;

const AddItemToSymptomType = ({ handleAddItem, symptomtypeItems }) => {
  const { response: items = [] } = useGet(API_URLS.ITEMS, "", true, {
    "cache-control": "no-cache",
  });
  const [searchText, setSearchText] = useState("");

  const noDuplicateItemList = items.filter((item) => {
    return (
      symptomtypeItems &&
      !symptomtypeItems
        .map((q) => q.itemTemplateId)
        .includes(item.itemTemplateId)
    );
  });

  const filteredItems = noDuplicateItemList.filter((item) => {
    return (
      item.phrase &&
      (item.phrase.toLowerCase().includes(searchText.toLowerCase()) ||
        String(item.itemTemplateId).includes(searchText) ||
        (item.groupTitle &&
          item.groupTitle.toLowerCase().includes(searchText.toLowerCase())))
    );
  });

  const sortedItems = filteredItems.sort((a, b) => {
    return b.phrase < a.phrase ? 1 : b.phrase > a.phrase ? -1 : 0;
  });

  return (
    <Col lg={{ span: 12 }}>
      <StyledCard>
        <Card.Header>
          <H2>Lägg till item</H2>
        </Card.Header>
        <Card.Body>
          <SearchBar
            placeholder={"Sök efter fras eller id..."}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Table>
            <tbody>
              {sortedItems.length < 1 && searchText.length > 0 ? (
                <TableBodyRow>
                  <Td>Inga matchande items</Td>
                </TableBodyRow>
              ) : (
                sortedItems.map((item) => (
                  <TableBodyRow
                    onClick={() =>
                      handleAddItem(
                        item.itemTemplateId,
                        item.phrase,
                        item.groupTitle,
                        item.itemType,
                      )
                    }
                    key={item.itemTemplateId}
                  >
                    <Td>Id:&nbsp;({item.itemTemplateId})</Td>
                    <Td>{item.groupTitle}</Td>
                    <Td>{item.phrase}</Td>
                    <Td>{item.itemType && itemTypes[item.itemType]}</Td>
                  </TableBodyRow>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </StyledCard>
    </Col>
  );
};

export default AddItemToSymptomType;
