import {generatePath} from "react-router-dom";
import {Table, TableBodyRow, Td, H2, Input} from "@collabodoc/component-library";
import {VIEWS} from "../../URLS";
import {Col, Card} from "react-bootstrap";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 50px;
  background-color: #fff;
  border: ${(props) => props.showError && 'solid 2px red'};
  
  &:focus {
    border: ${(props) => props.showError && 'solid 2px red'};
  }
  
  /*Remove arrows from number input*/
  ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }  
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-top: 10px;
`

const StyledTd = styled(Td)`
  vertical-align: middle !important;
`

const InputTd = styled(Td)`
  cursor: default;
`

const getNumberOrNull = (elem) => elem.value === "" ? null : elem.valueAsNumber;

const getValueOrEmptyString = (value) => value == null ? "" : value;


const StatusTemplates = ({statustemplates = [], handleRemoveStatusTemplateFromGroup, handleStatusListChange}) => {
  const navigate = useNavigate();
  const sortedStatuses = statustemplates && statustemplates.sort((a, b) => {
        return a.Id < b.Id ? -1 : 1
    })

  return (
      <Col lg={{span: 8}}>
        <Card>
          <Card.Header><H2>Tillhörande statusar</H2>
          </Card.Header>
          <Card.Body>
            <Table>
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Grouptitle</th>
                    <th>Phrase</th>
                    <th>Ordning</th>
                    <th>Kolumn</th>
                    <th></th>
                </tr>
                </thead>
              <tbody>
              {!sortedStatuses
                  ?
                  <TableBodyRow>
                    <Td>Inga tillhörande statusar</Td>
                  </TableBodyRow>
                  :
                  sortedStatuses.map(status => (
                      <TableBodyRow
                          key={status.statusTemplateId}>
                        <Td onClick={() => navigate(generatePath(VIEWS.QUESTION, {Id: status.Id}))}>Id:&nbsp;({status.statusTemplateId})</Td>
                        <Td onClick={() => navigate(generatePath(VIEWS.QUESTION, {Id: status.Id}))}>{status.groupTitle}</Td>

                        <Td onClick={() => navigate(generatePath(VIEWS.QUESTION, {Id: status.Id}))}>{status.phrase}</Td>
                        <InputTd><StyledInput type={"number"}
                                                    value={getValueOrEmptyString(status.groupIndex)}
                                                    showError={status.groupIndex == null}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => handleStatusListChange(status.statusTemplateId, getNumberOrNull(e.target), status.column)}/></InputTd>
                        <InputTd><StyledInput type={"number"}
                                                      value={getValueOrEmptyString(status.column)}
                                                      showError={status.column == null}
                                                      onClick={(e) => e.stopPropagation()}
                                                      onChange={(e) => handleStatusListChange(status.statusTemplateId, status.groupIndex, getNumberOrNull(e.target))}/></InputTd>
                          <StyledTd
                              onClick={(e) => handleRemoveStatusTemplateFromGroup(status.statusTemplateId)}
                          >
                              <StyledIcon icon={faTrashCan} />
                          </StyledTd>
                      </TableBodyRow>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
  );
}

export default StatusTemplates;