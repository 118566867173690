import {useGet, Table, TableBodyRow, Td, H2} from "@collabodoc/component-library";
import {API_URLS} from "../../URLS";
import {Col, Card} from "react-bootstrap";
import {useState} from "react";
import styled from "styled-components";

const SearchBar = styled.input`
  width: 100%;
  margin-bottom: 10px;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 5px;
  outline: none;
`

const StyledCard = styled(Card)`
  margin-top: 15px;
`

const AddQuestionToSymptomType = ({handleAddQuestion, symptomtypeQuestions}) => {
  const {response: questions = []} = useGet(API_URLS.QUESTIONS, "", true, {"cache-control": "no-cache"});
  const [searchText, setSearchText] = useState("");

  const noDuplicateQuestionList = questions.filter((question) => {
    return symptomtypeQuestions && !symptomtypeQuestions.map(q => q.patientQuestionTemplateId).includes(question.patientQuestionTemplateId);
  })

  const filteredQuestions = noDuplicateQuestionList.filter((question) => {
    return question.phrase && (question.phrase.toLowerCase().includes(searchText.toLowerCase()) || String(question.patientQuestionTemplateId).includes(searchText))
  })

  const sortedQuestions = filteredQuestions.sort((a, b) => {
    return b.phrase < a.phrase ? 1 : b.phrase > a.phrase ? -1 : 0;
  })

  return (
      <Col lg={{span: 12}}>
        <StyledCard>
          <Card.Header><H2>Lägg till fråga</H2></Card.Header>
          <Card.Body>
            <SearchBar placeholder={"Sök efter fras eller id..."} onChange={(e) => setSearchText(e.target.value)}/>
            <Table>
              <tbody>
              {sortedQuestions.length < 1 && searchText.length > 0
                  ?
                  <TableBodyRow>
                    <Td>Inga matchande frågor</Td>
                  </TableBodyRow>
                  :
                  sortedQuestions.map(question => (
                      <TableBodyRow
                          onClick={() => handleAddQuestion(question.patientQuestionTemplateId, question.phrase)}
                          key={question.patientQuestionTemplateId}>
                        <Td>Id:&nbsp;({question.patientQuestionTemplateId})</Td>
                        <Td>{question.phrase}</Td>
                      </TableBodyRow>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </StyledCard>
      </Col>
  );
}

export default AddQuestionToSymptomType;
