import {
  DropDown,
  TextInputWithLabelAndError,
  Input,
  PrimaryButton,
  SecondaryButton,
  usePut,
  usePost,
  H4,
  H2,
  CheckboxWithLabel,
} from "@collabodoc/component-library";
import { inputTemplateIdEnum} from "../../enums";
import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { API_URLS, VIEWS } from "../../URLS";
import { useNavigate } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import AddStatusToFamily from "./AddStatusToFamily";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const IconButton = styled(PrimaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const StyledCol = styled(Col)`
  margin-left: ${(props) => props.level}0%;
`;

const StyledH4 = styled(H4)`
  display: flex;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledCardHeader = styled(Card.Header)`
  cursor: pointer;
`;
styled(Input)`
  border: ${(props) => props.showError && "solid 2px red"};

  &:focus {
    border: ${(props) => props.showError && "solid 2px red"};
  }

  /*Remove arrows from number input*/

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledCard = styled(Card)`
  margin-top: 15px;
`;

const FormErrorMessage = styled.span`
  color: red;
`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StatusTemplate = ({
  item = [],
  familyIds,
  level,
  isNewItem: isNewItem = false,
  isChildItem: isChildItem = false,
  reFetchItem: reFetchItem,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [editGroupTitleForGroup, setEditGroupTitleForGroup] = useState(false);

  const { doPut } = usePut(
    API_URLS.PATCHSTATUS(item.statusTemplateId, editGroupTitleForGroup),
    accessToken,
  );
  const { doPut: removeParentId } = usePut(
    API_URLS.REMOVEPARENTSTATUSID(item.statusTemplateId),
    accessToken,
  );
  const { doPost } = usePost(API_URLS.POSTSTATUS, accessToken);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showRelationModal, setShowRelationModal] = useState(false);
  const [isChildItemRelation, setIsChildItemRelation] = useState(null);
  const [itemState, setItemState] = useState({});
  const [formStateError, setFormStateError] = useState(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [initialValueChanged, setInitialValueChanged] = useState(false);

  useEffect(() => {
    !isNewItem && setItemState(item);
  }, [item, setItemState, isNewItem]);

  useEffect(() => {
    isNewItem || itemId === String(item.statusTemplateId)
      ? setIsCollapsed(false)
      : setIsCollapsed(true);
  }, [isNewItem, itemId, item.statusTemplateId]);

  useEffect(() => {
    if (initialValueChanged) {
      setIsDisabledSaveButton(false);
    }
  }, [initialValueChanged]);

  const handleItemChange = (key, value) => {
    setInitialValueChanged(true);

    setItemState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const InputTypeOptions = Object.entries(inputTemplateIdEnum).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  const openRelationModal = () => {
    setShowRelationModal(true);
  };

  const openChildModalHandler = () => {
    setIsChildItemRelation(true);
  };

  const openParentModalHandler = () => {
    setIsChildItemRelation(false);
  };

  const postItem = () => {
    setIsDisabledSaveButton(true);
    
    doPost({
      phrase: itemState.phrase,
      groupTitle: itemState.groupTitle,
      inputTemplateId: itemState.inputTemplateId || null,
      parentId: itemState.parentStatusTemplateId,
    }).then(() => setItemState([]));
  };

  const patchItem = () => {
    setIsDisabledSaveButton(true);
    setInitialValueChanged(false);

    const obj = {
      phrase: itemState.phrase,
      groupTitle: itemState.groupTitle,
      inputTemplateId: itemState.inputTemplateId || null,
      parentId: itemState.parentStatusTemplateId,
    };
    doPut(obj).then(() => {
      if (editGroupTitleForGroup) {
        reFetchItem();
        setEditGroupTitleForGroup(false);
      }
    });
  };

  const removeParentItem = () => {
    window.confirm("Kapa släktträd?") &&
      removeParentId({
        parentId: null,
      }).then(() => reFetchItem());
  };

  return (
    <Row>
      <StyledCol lg={{ span: 4 }} level={level}>
        <Card>
          <StyledCardHeader onClick={() => setIsCollapsed(!isCollapsed)}>
            {isNewItem ? (
              <StyledH4>
                Ny item{" "}
                {isCollapsed ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </StyledH4>
            ) : (
              <StyledH4>
                <span>
                  {itemState.groupTitle || ""} - {itemState.phrase || ""} (
                  {itemState.statusTemplateId || ""})
                </span>
                {isCollapsed ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </StyledH4>
            )}
          </StyledCardHeader>
          {!isCollapsed && (
            <Card.Body>
              <StyledWrapper>
                <div>
                  {!isNewItem && level < 3 && (
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => openRelationModal()}
                    >
                      <span>Skapa relation</span>
                    </StyledPrimaryButton>
                  )}
                  {!isNewItem && level > 0 && (
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => removeParentItem()}
                    >
                      <span>Kapa släktträd</span>
                    </StyledPrimaryButton>
                  )}
                </div>
                {isNewItem ? (
                  <IconButton
                    type="button"
                    disabled={isDisabledSaveButton}
                    onClick={() => postItem()}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    <span>Spara</span>
                  </IconButton>
                ) : (
                  <IconButton
                    type="button"
                    disabled={isDisabledSaveButton}
                    onClick={() => patchItem()}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    <span>Spara</span>
                  </IconButton>
                )}
              </StyledWrapper>
              {formStateError && (
                <FormErrorMessage>
                  Alla röda rutor måste fyllas i
                </FormErrorMessage>
              )}
              {!isNewItem && (
                <div>
                  <label>
                    StatusTemplateId: {itemState.statusTemplateId || ""}
                  </label>
                </div>
              )}
              {isChildItem && !isNewItem && (
                <div>
                  <label>
                    ParentItemTemplateId: {itemState.parentStatusTemplateId}
                  </label>
                </div>
              )}

              <TextInputWithLabelAndError
                label={"Fras"}
                value={itemState.phrase || ""}
                handleChange={(value) => handleItemChange("phrase", value)}
              />

              <TextInputWithLabelAndError
                value={itemState.groupTitle || ""}
                handleChange={(value) => handleItemChange("groupTitle", value)}
                label={"GroupTitle"}
              />
              <CheckboxWithLabel
                label={"Uppdatera items med samma group title"}
                checked={editGroupTitleForGroup}
                onChange={() =>
                  setEditGroupTitleForGroup(!editGroupTitleForGroup)
                }
              />
              <label>
                InputTemplateId: ({itemState.inputTemplateId || ""})
              </label>
              <DropDown
                options={InputTypeOptions}
                selected={
                  inputTemplateIdEnum[itemState.inputTemplateId] ||
                  "Ej specificerat"
                }
                handler={(e) => handleItemChange("inputTemplateId", Number(e))}
              ></DropDown>
            </Card.Body>
          )}
        </Card>
      </StyledCol>

      <Modal
        show={showRelationModal}
        onHide={() => setShowRelationModal(false)}
        dialogClassName={"modal-dialog modal-lg"}
      >
        <Col lg={{ span: 12 }}>
          <StyledCard>
            <Card.Header>
              <H2>Lägg till status</H2>
            </Card.Header>
            <StyledCardBody>
              {level < 3 && isChildItem ? (
                <>
                  <StyledWrapper>
                    <div />
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => navigate(VIEWS.NEWSTATUSTEMPLATE)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Skapa ny status</span>
                    </StyledPrimaryButton>
                  </StyledWrapper>
                  <AddStatusToFamily
                    item={itemState}
                    familyIds={familyIds}
                    isChildItemRelation={true}
                    reFetchItem={reFetchItem}
                  />
                </>
              ) : (
                <>
                  <StyledWrapper>
                    <div>
                      {!isChildItemRelation ? (
                        <>
                          <StyledSecondaryButton
                            type="button"
                            onClick={() => openChildModalHandler()}
                          >
                            <span>Barnitem</span>
                          </StyledSecondaryButton>
                          <StyledPrimaryButton
                            type="button"
                            onClick={() => openParentModalHandler()}
                          >
                            <span>Förälderitem</span>
                          </StyledPrimaryButton>
                        </>
                      ) : (
                        <>
                          <StyledPrimaryButton
                            type="button"
                            onClick={() => openChildModalHandler()}
                          >
                            <span>Barnitem</span>
                          </StyledPrimaryButton>
                          <StyledSecondaryButton
                            type="button"
                            onClick={() => openParentModalHandler()}
                          >
                            <span>Förälderitem</span>
                          </StyledSecondaryButton>
                        </>
                      )}
                    </div>
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => navigate(VIEWS.NEWSTATUSTEMPLATE)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Skapa ny status</span>
                    </StyledPrimaryButton>
                  </StyledWrapper>
                  <AddStatusToFamily
                    item={itemState}
                    familyIds={familyIds}
                    isChildItemRelation={isChildItemRelation}
                    reFetchItem={reFetchItem}
                  />
                </>
              )}
            </StyledCardBody>
          </StyledCard>
        </Col>
      </Modal>
    </Row>
  );
};

export default StatusTemplate;
