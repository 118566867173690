import React from "react";
import Question from "./Question";

const SubQuestion = ({question, level, reFetchQuestion, familyIds}) => {
  
  return (
      <>
        <Question question={question} familyIds={familyIds} level={level} isChildQuestion={true} reFetchQuestion={reFetchQuestion}/>
        {question.childPatientQuestionTemplates.map(question => (
            <SubQuestion key={Math.random()} question={question} familyIds={familyIds} level={level + 1} reFetchQuestion={reFetchQuestion}/>))}
      </>
  )
}

export default SubQuestion; 
