import { generatePath, useParams } from "react-router-dom";
import {
  DropDown,
  SecondaryButton,
  PrimaryButton,
  TextAreaWithLabelAndError,
  useGet,
  usePatch,
  H2,
  Input,
  usePost,
  LoadingView,
    useFetchy,
  getRequestInit
} from "@collabodoc/component-library";
import { Modal } from "react-bootstrap";
import { API_URLS, VIEWS } from "../../URLS";
import { Col, Row } from "react-bootstrap";
import { symptomTypeSort } from "../../enums";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCopy,
  faFloppyDisk,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SymptomTypeQuestions from "./SymptomTypeQuestions";
import AddQuestionToSymptomType from "./AddQuestionToSymptomType";
import { useEffect, useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import SymptomTypeItems from "./SymptomTypeItems";
import AddItemToSymptomType from "./AddItemToSymptomType";
import SymptomTypeStatusGroups from "./SymptomTypeStatusGroups";
import AddStatusGroupToSymptomForm from "./AddStatusGroupToSymptomForm";

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const BackButton = styled(SecondaryButton)`
  margin-right: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const MarginButton = styled(PrimaryButton)`
  margin-left: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const MarginSecondaryButton = styled(SecondaryButton)`
  margin-left: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const FormErrorMessage = styled.span`
  color: red;
`;

const StyledInput = styled(Input)`
  border: ${(props) => props.showError && "solid 2px red"};

  &:focus {
    border: ${(props) => props.showError && "solid 2px red"};
  }
`;

const SymptomType = () => {
  const { accessToken } = useOidcAccessToken();
  const { symptomTypeId } = useParams();
  const navigate = useNavigate();
  const { doFetch: fetch } = useFetchy();
  
  const { response: symptomtypeResponse } = useGet(
    API_URLS.SYMPTOMTYPE(symptomTypeId),
    "",
    true,
    { "cache-control": "no-cache" },
  );
  const { response: symptomtypeQuestionsResponse } = useGet(
    API_URLS.SYMPTOMTYPEQUESTIONS(symptomTypeId),
    "",
    true,
    { "cache-control": "no-cache" },
  );

  const { response: symptomtypeItemsResponse } = useGet(
    API_URLS.SYMPTOMTYPEITEMS(symptomTypeId),
    "",
    true,
    { "cache-control": "no-cache" },
  );

  const { response: symptomtypeStatusGroupsResponse = [], doGet: reFetchStatusGroups } = useGet(
      API_URLS.STATUSGROUPONSYMPTOMTYPE(symptomTypeId),
      "",
      true,
      { "cache-control": "no-cache" }
  );
  
  const { doPost } = usePost(
    API_URLS.COPYSYMPTOMTYPE(symptomTypeId),
    accessToken,
  );

  const { doPatch } = usePatch(
    API_URLS.PATCHSMPTOMTYPE(symptomTypeId),
    accessToken,
  );
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showAddStatusGroupModal, setShowAddStatusGroupModal] = useState(false);
  const [formState, setFormState] = useState({});
  const [formStateError, setFormStateError] = useState(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [initialValueChanged, setInitialValueChanged] = useState(false);
  const [errors, setErrors] = useState();

  const handleAddStatusGroup = async (statusGroupId) => {
    const data = {statusGroupId}
    const url = API_URLS.STATUSGROUPONSYMPTOMTYPE(symptomTypeId);
    const init = getRequestInit({ accessToken, method: "PUT", data });
    await fetch(url, init).then(( {response} ) => {
      if (response.ok) {
        reFetchStatusGroups();
        setShowAddStatusGroupModal(false);
      } else {
        setErrors(["Kunde inte lägga till statusmall på symptomtyp!"]);
      }
    });
  }
  
  const handleRemoveStatusGroup = async (statusGroupId) => {
    const data = {statusGroupId}
    const url = API_URLS.STATUSGROUPONSYMPTOMTYPE(symptomTypeId);
    const init = getRequestInit({ accessToken, method: "DELETE", data });
    await fetch(url, init).then(( {response} ) => {
      if (response.ok) {
        reFetchStatusGroups();
      } else {
        setErrors(["Kunde inte ta bort statusmall från symptomtyp!"]);
      }
    });
  }
  
  useEffect(() => {
    symptomtypeQuestionsResponse &&
      symptomtypeResponse &&
      symptomtypeItemsResponse &&
      setFormState({
        id: symptomtypeResponse.symptomTypeTemplateId,
        multiCulturePhrase: symptomtypeResponse.multiCultureName,
        sort: symptomtypeResponse.sort,
        svgIcon: symptomtypeResponse.svgIcon,
        questions: symptomtypeQuestionsResponse.map((question) => ({
          patientQuestionTemplateId: question.patientQuestionTemplateId,
          phrase: question.phrase,
          groupIndex: question.layoutTemplate.groupIndex,
          index: question.layoutTemplate.index,
          column: question.layoutTemplate.column,
          journalSentenceIndex: question.journalSentenceIndex,
        })),
        items: symptomtypeItemsResponse.map((item) => ({
          itemTemplateId: item.itemTemplateId,
          itemType: item.itemType,
          phrase: item.phrase,
          groupTitle: item.groupTitle,
          groupIndex: item.layoutTemplate.groupIndex,
          index: item.layoutTemplate.index,
          column: item.layoutTemplate.column,
        })),
      });
  }, [
    symptomtypeQuestionsResponse,
    symptomtypeResponse,
    symptomtypeItemsResponse,
  ]);

  useEffect(() => {
    if (
      (formState.questions &&
        formState.questions.some(
          (q) => q.groupIndex == null || q.index == null || q.column == null,
        )) ||
      (formState.items &&
        formState.items.some(
          (q) => q.groupIndex == null || q.index == null || q.column == null,
        )) ||
      (formState.multiCulturePhrase && !formState.multiCulturePhrase["sv-SE"])
    ) {
      setFormStateError(true);
      if (initialValueChanged) {
        setIsDisabledSaveButton(true);
      }
    } else {
      setFormStateError(false);
      if (initialValueChanged) {
        setIsDisabledSaveButton(false);
      }
    }
  }, [formState, initialValueChanged]);

  const handleFormChange = (key, value) => {
    setInitialValueChanged(true);

    if (key === "multiCulturePhrase") {
      const phrase = {
        multiCulturePhrase: {
          ...formState[key],
          ...value,
        },
      };
      setFormState((prevState) => ({
        ...prevState,
        ...phrase,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleQuestionListChange = (
    id,
    phrase,
    groupIndex,
    index,
    column,
    journalSentenceIndex,
  ) => {
    const formQuestions = formState["questions"];
    const formQuestionIndex = formQuestions.findIndex(
      (q) => q.patientQuestionTemplateId === id,
    );

    setInitialValueChanged(true);

    formQuestions[formQuestionIndex] = {
      patientQuestionTemplateId: id,
      phrase: phrase,
      groupIndex: groupIndex,
      index: index,
      column: column,
      journalSentenceIndex: journalSentenceIndex,
    };

    setFormState((prevState) => ({
      ...prevState,
      questions: formQuestions,
    }));
  };

  const handleItemListChange = (
    id,
    phrase,
    groupTitle,
    itemType,
    groupIndex,
    index,
    column,
  ) => {
    const formItems = formState["items"];
    const formItemIndex = formItems.findIndex((q) => q.itemTemplateId === id);

    setInitialValueChanged(true);

    formItems[formItemIndex] = {
      itemTemplateId: id,
      groupTitle: groupTitle,
      phrase: phrase,
      itemType: itemType,
      groupIndex: groupIndex,
      index: index,
      column: column,
    };
    setFormState((prevState) => ({
      ...prevState,
      items: formItems,
    }));
  };

  const handleAddQuestion = (id, phrase) => {
    setInitialValueChanged(true);

    setFormState((prevState) => {
      return {
        ...prevState,
        questions: [
          ...prevState.questions,
          {
            patientQuestionTemplateId: id,
            phrase: phrase,
            groupIndex: 0,
            index: 0,
            column: 0,
            journalSentenceIndex: 0,
          },
        ],
      };
    });
    setShowAddQuestionModal(false);
  };

  const handleAddItem = (id, phrase, groupTitle, itemType) => {
    setInitialValueChanged(true);

    setFormState((prevState) => {
      return {
        ...prevState,
        items: [
          ...prevState.items,
          {
            itemTemplateId: id,
            phrase: phrase,
            groupTitle: groupTitle,
            itemType: itemType,
            groupIndex: 0,
            index: 0,
            column: 0,
          },
        ],
      };
    });

    setShowAddItemModal(false);
  };

  const handleRemoveQuestion = (e, id) => {
    setInitialValueChanged(true);

    e.stopPropagation();
    setFormState((prevState) => ({
      ...prevState,
      questions: prevState.questions.filter(
        (questions) => questions.patientQuestionTemplateId !== id,
      ),
    }));
  };

  const handleRemoveItem = (e, id) => {
    setInitialValueChanged(true);

    e.stopPropagation();
    setFormState((prevState) => ({
      ...prevState,
      items: prevState.items.filter((items) => items.itemTemplateId !== id),
    }));
  };
  const sortOptions = Object.entries(symptomTypeSort).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  const sendData = () => {
    setIsDisabledSaveButton(true);

    doPatch({
      sort: formState.sort,
      mappedQuestions: formState.questions.map((q) => ({
        patientQuestionId: q.patientQuestionTemplateId,
        pageNumber: q.groupIndex,
        patientViewOrder: q.index,
        careGiverViewOrder: q.column,
        journalSentenceIndex: q.journalSentenceIndex,
      })),
      mappedItems: formState.items.map((i) => ({
        itemTemplateId: i.itemTemplateId,
        pageNumber: i.groupIndex,
        patientViewOrder: i.index,
        careGiverViewOrder: i.column,
      })),
      symptomTypeNames: formState.multiCulturePhrase,
      icon: formState.svgIcon,
    });
  };

  return (
    <>
      <PageHeader>
        <BackButton type="button" onClick={() => navigate(VIEWS.SYMPTOMTYPES)}>
          <FontAwesomeIcon icon={faAngleLeft} />
          <span>Till symptomtyper</span>
        </BackButton>
        <div>
          {formStateError && (
            <FormErrorMessage>Alla röda rutor måste fyllas i</FormErrorMessage>
          )}
          {errors && <FormErrorMessage>{errors}</FormErrorMessage>}
          <MarginButton
            type="button"
            onClick={() => setShowAddQuestionModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Lägg till fråga</span>
          </MarginButton>
          <MarginButton type="button" onClick={() => setShowAddItemModal(true)}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Lägg till item</span>
          </MarginButton>
          <MarginButton
              type="button"
              onClick={() => setShowAddStatusGroupModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Lägg till statusmall</span>
          </MarginButton>
          <MarginSecondaryButton
            title={
              "Kopierar patientfrågor och status + treatment items. (EJ diagnoskoder)"
            }
            type="button"
            onClick={() =>
              doPost().then((response) =>
                navigate(
                  generatePath(VIEWS.SYMPTOMTYPE, { symptomTypeId: response }),
                ),
              )
            }
          >
            <FontAwesomeIcon icon={faCopy} />
            <span>Kopiera symptomtyp</span>
          </MarginSecondaryButton>
          <MarginButton
            type="button"
            disabled={isDisabledSaveButton}
            onClick={() => sendData()}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
            <span>Spara ändringar</span>
          </MarginButton>
        </div>
      </PageHeader>
      <Row>
        {Object.entries(formState).length < 1 ? (
          <Col lg={{ span: 4 }}>
            <Card>
              <Card.Header>
                Det finns ingen symptomtyp med id {symptomTypeId}
              </Card.Header>
            </Card>
          </Col>
        ) : (
          <Col lg={{ span: 4 }}>
            <Card>
              <Card.Header>
                <H2>Editera symptomtyp</H2>
              </Card.Header>
              <Card.Body>
                <b>
                  <p>Id: {formState.id || ""}</p>
                </b>
                <label htmlFor={"sv-SE"}>Namn (sv)</label>
                <StyledInput
                  id={"sv-SE"}
                  type={"text"}
                  value={formState.multiCulturePhrase["sv-SE"] || ""}
                  showError={!formState.multiCulturePhrase["sv-SE"]}
                  onChange={(e) =>
                    handleFormChange("multiCulturePhrase", {
                      "sv-SE": e.target.value,
                    })
                  }
                />
                <label htmlFor={"en-US"}>Namn (en)</label>
                <StyledInput
                  id={"en-US"}
                  type={"text"}
                  value={formState.multiCulturePhrase["en-US"] || ""}
                  onChange={(e) =>
                    handleFormChange("multiCulturePhrase", {
                      "en-US": e.target.value,
                    })
                  }
                />
                <label htmlFor={"de-DE"}>Namn (de)</label>
                <StyledInput
                  id={"de-DE"}
                  type={"text"}
                  value={formState.multiCulturePhrase["de-DE"] || ""}
                  onChange={(e) =>
                    handleFormChange("multiCulturePhrase", {
                      "de-DE": e.target.value,
                    })
                  }
                />
                <TextAreaWithLabelAndError
                  value={formState.svgIcon || ""}
                  handleChange={(e) => handleFormChange("svgIcon", e)}
                  minRows={3}
                  maxRows={8}
                  label={"SVG Icon"}
                />
                <b>
                  <label>Sort</label>
                </b>
                <DropDown
                  options={sortOptions}
                  selected={symptomTypeSort[formState.sort]}
                  handler={(e) => handleFormChange("sort", Number(e))}
                ></DropDown>
              </Card.Body>
            </Card>
          </Col>
        )}
        <SymptomTypeQuestions
          symptomtypeQuestions={formState.questions}
          handleRemoveQuestion={handleRemoveQuestion}
          handleQuestionListChange={handleQuestionListChange}
        />
        <SymptomTypeItems
          symptomtypeItems={formState.items}
          handleRemoveItem={handleRemoveItem}
          handleItemListChange={handleItemListChange}
        />
        <SymptomTypeStatusGroups
          statusGroups={symptomtypeStatusGroupsResponse}
          handleRemoveStatusGroup={handleRemoveStatusGroup}
          />
        <Modal
          show={showAddQuestionModal}
          onHide={() => setShowAddQuestionModal(false)}
          dialogClassName={"modal-dialog modal-lg"}
        >
          <AddQuestionToSymptomType
            handleAddQuestion={handleAddQuestion}
            symptomtypeQuestions={formState.questions}
          />
        </Modal>
        <Modal
          show={showAddItemModal}
          onHide={() => setShowAddItemModal(false)}
          dialogClassName={"modal-dialog modal-lg"}
        >
          <AddItemToSymptomType
            handleAddItem={handleAddItem}
            symptomtypeItems={formState.items}
          />
        </Modal>
        <Modal
            show={showAddStatusGroupModal}
            onHide={() => setShowAddStatusGroupModal(false)}
            dialogClassName={"modal-dialog modal-lg"}
        >
          <AddStatusGroupToSymptomForm
              handleAddStatusGroup={handleAddStatusGroup}
              symptomtypeStatusGroups={symptomtypeStatusGroupsResponse}
          />
        </Modal>
      </Row>
    </>
  );
};

export default SymptomType;
