import {
    SecondaryButton,
    Table,
    TableBodyRow,
    Td,
    Th,
    useGet,
    LoadingView,
    PrimaryButton,
    Input,
    H2,
    getRequestInit,
    useFetchy
} from "@collabodoc/component-library";
import {generatePath, useNavigate} from "react-router-dom";
import {API_URLS, VIEWS} from "../URLS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import React, {useRef, useState} from "react";
import {Card, Col, Modal} from "react-bootstrap";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const PageHeader = styled.section`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`

const IconButton = styled(SecondaryButton)`
    svg:first-child {
        margin-right: 5px;
    }
`

const ErrorMessage = styled.span`
    color: red;
`;

const IconPrimaryButton = styled(PrimaryButton)`
    svg:first-child {
        margin-right: 5px;
    }
`

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const StatusGroupsView = () => {
    const {
        response = [],
        isLoading,
        doGet: reFetchGroups
    } = useGet(API_URLS.STATUSGROUPS, "", true, {"cache-control": "no-cache"})
    const navigate = useNavigate();
    const [showCreateNewModal, setShowCreateNewModal] = useState(false);
    const [errors, setErrors] = useState();
    const newGroupNameRef = useRef(null);
    const { doFetch: fetch } = useFetchy();
    const accessToken = useOidcAccessToken();

    const handleCreateNewStatusGroup = async (name) => {
        const data = {name: name}
        const url = API_URLS.STATUSGROUPS;
        const init = getRequestInit({ accessToken, method: "POST", data });
        await fetch(url, init).then(( {response} ) => {
            if (response.ok) {
                setShowCreateNewModal(false);
                reFetchGroups();
            } else {
                setErrors(["Kunde inte spara statusmall!"]);
            }
        });
    }

    if (isLoading || !response) {
        return <LoadingView/>;
    }
    return (
        <>
            <PageHeader>
                <IconButton type="button" onClick={() => navigate(VIEWS.START)}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                    <span>Till startsida</span>
                </IconButton>
                {errors && <ErrorMessage>{errors}</ErrorMessage>}
                <IconPrimaryButton type="button" onClick={() => setShowCreateNewModal(true)}>
                    <FontAwesomeIcon icon={faPlus}/>
                    <span>Skapa ny statusmall</span>
                </IconPrimaryButton>
            </PageHeader>
            <Table>
                <thead>
                <tr>
                    <Th width="5%">Id</Th>
                    <Th>Statusmall</Th>
                    <Th>Finns på följande symptomtyper</Th>
                </tr>
                </thead>
                <tbody>
                {response.map(statusGroup => (
                    <TableBodyRow
                        onClick={() => navigate(generatePath(VIEWS.STATUSGROUP, {statusGroupId: statusGroup.id}))}
                        key={statusGroup.id}>
                        <Td>({statusGroup.id})</Td>
                        <Td>
                            {statusGroup.statusGroupName}
                        </Td>
                        <Td>
                            {Object.values(statusGroup.symptomTypes).join(', ')}
                        </Td>
                    </TableBodyRow>
                ))}
                </tbody>
            </Table>
            <Modal
                show={showCreateNewModal}
                onHide={() => setShowCreateNewModal(false)}
                dialogClassName={"modal-dialog modal-lg"}
            >
                <Col lg={{span: 12}}>
                    <Card>
                        <Card.Header>
                            <H2>Lägg till statusmall</H2>
                        </Card.Header>
                        <Card.Body>
                            <StyledWrapper>
                                <Col lg={{span: 8}}>
                                    <Input width={"30px"} ref={newGroupNameRef}/>
                                </Col>
                                <Col lg={{span: 2}}>
                                </Col>
                                <Col lg={{span: 2}}>
                                    <PrimaryButton
                                        type="button"
                                        onClick={() => (handleCreateNewStatusGroup(newGroupNameRef.current.value))}
                                    >
                                        <FontAwesomeIcon icon={faPlus}/>
                                        <span>Skapa</span>
                                    </PrimaryButton>
                                </Col>
                            </StyledWrapper>
                        </Card.Body>
                    </Card>
                </Col>
            </Modal>
        </>
    )
}

export default StatusGroupsView;