import {
    useGet,
    Table,
    TableBodyRow,
    Td,
    H2,
} from "@collabodoc/component-library";
import {API_URLS} from "../../URLS";
import {Col, Card} from "react-bootstrap";
import {useState} from "react";
import styled from "styled-components";
import {multiFilter, sortAscendingByKey} from "../../helpers/sortFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFolderTree
} from "@fortawesome/free-solid-svg-icons";

const SearchBar = styled.input`
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px gray;
    border-radius: 3px;
    padding: 5px;
    outline: none;
`;

const StyledCard = styled(Card)`
    margin-top: 15px;
`;

const AddStatusToStatusGroup = ({handleAddStatus, statuses}) => {
    const {response: items = []} = useGet(API_URLS.STATUSTEMPLATES, "", true, {
        "cache-control": "no-cache",
    });
    const [searchText, setSearchText] = useState("");


    const onlyParents = items.filter((item) => {
        return (
            item.parentStatusTemplateId === null
        );
    });

    const noDuplicateItemList = onlyParents.filter((item) => {
        return (
            !statuses?.includes(item.statusTemplateId)
        );
    });

    const filteredItems = multiFilter(noDuplicateItemList, searchText, [
        "phrase",
        "statusTemplateId",
        "groupTitle",
    ]);
    const sortedItems = sortAscendingByKey(filteredItems, "phrase");

    return (
        <Col lg={{span: 12}}>
            <StyledCard>
                <Card.Header>
                    <H2>Lägg till statustemplate</H2>
                </Card.Header>
                <Card.Body>
                    <SearchBar
                        placeholder={"Sök efter fras eller id..."}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Table>
                        <tbody>
                        {sortedItems.length < 1 && searchText.length > 0 ? (
                            <TableBodyRow>
                                <Td>Inga matchande statusar</Td>
                            </TableBodyRow>
                        ) : (
                            sortedItems.map((item) => (
                                <TableBodyRow
                                    onClick={() =>
                                        handleAddStatus(
                                            item.statusTemplateId
                                        )
                                    }
                                    key={item.statusTemplateId}
                                >
                                    <Td>Id:&nbsp;({item.statusTemplateId})</Td>
                                    <Td>{item.groupTitle}</Td>
                                    <Td>{item.phrase}</Td>
                                    <Td>
                                        {
                                            item.childStatusTemplates.length !== 0 &&
                                            <MyTooltip>
                                                <FontAwesomeIcon icon={faFolderTree}/>
                                                <MyTooltipText>
                                                    <ul>
                                                        {
                                                            item.childStatusTemplates.map(child => {
                                                                return (<li>{`${child.statusTemplateId}, ${child.phrase}`}</li>)
                                                            })
                                                        }
                                                    </ul>
                                                </MyTooltipText>
                                            </MyTooltip>
                                        }
                                    </Td>
                                </TableBodyRow>
                            ))
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
            </StyledCard>
        </Col>
    );
};

export default AddStatusToStatusGroup;

const MyTooltipText = styled.span`
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background-color: #A0F0EB;
    border: black;

    color: black;
    text-align: left;
    padding-top: 8px;
    border-radius: 8px 8px 8px 8px;
    top: 10px;
    left: 10px;
    width: 300px;
`;

const MyTooltip = styled.div`
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: default;

    &:hover ${MyTooltipText} {
        visibility: visible;
    }
`;
