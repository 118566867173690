import React from "react";
import SubQuestion from "./SubQuestion";
import {Card, Col} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {SecondaryButton, useGet} from "@collabodoc/component-library";
import {API_URLS, VIEWS} from "../../URLS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Question from "./Question";
import {useOidcAccessToken} from "@axa-fr/react-oidc";

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const getChildIds = (question) => {
  const ids = []
  if (question) {
    ids.push(question.patientQuestionTemplateId);
    question.childPatientQuestionTemplates.forEach(childQuestion => {
      ids.push(...getChildIds(childQuestion))
    });
  }
  return ids;
}

const PatientQuestion = () => {
  const {accessToken} = useOidcAccessToken();
  const navigate = useNavigate();
  const {questionId} = useParams();
  const {response: familyQuestion, doGet: reFetchQuestion} = useGet(API_URLS.QUESTIONWITHFAMILY(questionId), accessToken, true, {"cache-control": "no-cache"});
  const familyIds = getChildIds(familyQuestion);
  
  return (
      <>
        {familyQuestion
            ?
            <>
              <PageHeader>
                <IconButton type="button" onClick={() => navigate(VIEWS.QUESTIONS)}>
                  <FontAwesomeIcon icon={faAngleLeft}/>
                  <span>Till frågor</span>
                </IconButton>
              </PageHeader>
              <Question question={familyQuestion}
                        familyIds={familyIds}
                        level={0}
                        reFetchQuestion={reFetchQuestion}
                        isChildQuestion={familyQuestion.parentPatientQuestionTemplateId !== null}/>
              {familyQuestion.childPatientQuestionTemplates.map(question => (
                  <SubQuestion key={Math.random()} question={question} familyIds={familyIds} reFetchQuestion={reFetchQuestion} level={1}/>))}
            </>
            :
            <Col lg={{span: 4}}>
              <Card>
                <Card.Header>
                  Det finns ingen fråga med id {questionId}
                </Card.Header>
              </Card>
            </Col>
        }
      </>
  );
}

export default PatientQuestion;
