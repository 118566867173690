export const sortAscendingByKey = (list, key) => list.sort((a, b) => {
  const aValue = a[key] && a[key].toLowerCase();
  const bValue = b[key] && b[key].toLowerCase();
  return bValue < aValue ? 1 : bValue > aValue ? -1 : 0
})

export const multiFilter = (questions, searchText, keys) => {
  const searchWords = searchText.split(" ");
  return questions.filter(question => searchWords.every(searchWord => keys.some(key => question[key] && String(question[key]).toLowerCase().includes(searchWord))));
}
