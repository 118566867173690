import {useNavigate, useParams} from "react-router-dom";
import {
    H2,
    Input,
    PrimaryButton,
    SecondaryButton,
    useGet,
    useFetchy,
    getRequestInit
} from "@collabodoc/component-library";
import {Card, Col, Modal, Row} from "react-bootstrap";
import {API_URLS, VIEWS} from "../../URLS";
import {symptomTypeSort} from "../../enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faFloppyDisk, faPlus} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import StatusTemplates from "./StatusTemplates";
import AddStatusToStatusGroup from "./AddStatusToStatusGroup";

const PageHeader = styled.section`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`

const BackButton = styled(SecondaryButton)`
    margin-right: 5px;

    svg:first-child {
        margin-right: 5px;
    }`

const MarginButton = styled(PrimaryButton)`
    margin-left: 5px;

    svg:first-child {
        margin-right: 5px;
    }
`

const ErrorMessage = styled.span`
    color: red;
`

const StyledInput = styled(Input)`
    border: ${(props) => props.showError && 'solid 2px red'};

    &:focus {
        border: ${(props) => props.showError && 'solid 2px red'};
    }
`
const StatusGroup = ({isNew = false}) => {
    const {accessToken} = useOidcAccessToken();
    const {statusGroupId} = useParams();
    const navigate = useNavigate();
    const {
        response: statusgroupResponse,
        isLoading,
        doGet: reFetchGroup
    } = useGet(API_URLS.STATUSGROUP(statusGroupId), "", true, {"cache-control": "no-cache"});

    const [formState, setFormState] = useState({});
    const [formStateError, setFormStateError] = useState(false);
    const [error, setErrors] = useState();
    const [initialValueChanged, setInitialValueChanged] = useState(false);
    const {doFetch: fetch} = useFetchy();

    useEffect(() => {
        (statusgroupResponse && !isNew) && setFormState({
            statusGroupId: statusgroupResponse.id,
            statusGroupName: statusgroupResponse.statusGroupName,
            statusTemplates: statusgroupResponse.statusTemplates.map(status => ({
                statusTemplateId: status.statusTemplateId,
                phrase: status.phrase,
                parentId: status.parentId,
                groupTitle: status.groupTitle,
                column: status.layoutTemplate?.column,
                groupIndex: status.layoutTemplate?.groupIndex,
            }))
        })
    }, [statusgroupResponse])


    const handleStatusGroupNameChange = (value) => {
        setInitialValueChanged(true);

        setFormState(prevState => ({
            ...prevState,
            statusGroupName: value
        }));
    };

    const handleStatusListChange = (id, groupIndex, column) => {
        const formStatusTemplates = formState['statusTemplates'];
        const formStatusTemplateIndex = formStatusTemplates.findIndex(s => s.statusTemplateId === id);

        setInitialValueChanged(true);

        formStatusTemplates[formStatusTemplateIndex].groupIndex = groupIndex;
        formStatusTemplates[formStatusTemplateIndex].column = column;

        setFormState(prevState => ({
            ...prevState,
            statusTemplates: formStatusTemplates
        }))
    }

    const handleAddStatus = async (statusId) => {
        const data = {statusTemplateId: statusId}
        const url = API_URLS.STATUSGROUP(statusGroupId);
        const init = getRequestInit({accessToken, method: "POST", data});
        await fetch(url, init).then(({response}) => {
            if (response.ok) {
                reFetchGroup();
            } else {
                setErrors(["Kunde inte lägga till status"]);
            }
        });
        setShowAddStatusModal(false)
    }

    const handleRemoveStatus = (e, id) => {
        setInitialValueChanged(true);

        e.stopPropagation();
        setFormState(prevState => ({
            ...prevState,
            statusTemplates: prevState.statusTemplates.filter(statuses => statuses.statusTemplateId !== id)
        }));
    }

    const [showAddStatusModal, setShowAddStatusModal] = useState(false);

    const sendData = async () => {
        const data = {...formState}
        const url = API_URLS.STATUSGROUP(statusGroupId);
        const init = getRequestInit({accessToken, method: "PUT", data});
        await fetch(url, init).then(({response}) => {
            if (response.ok) {
                setInitialValueChanged(false);
                reFetchGroup();
            } else {
                setErrors(["Kunde inte spara statusmall!"]);
            }
        });
    }

    const handleRemoveStatusTemplateFromGroup = async (statusTemplateId) => {
        const url = API_URLS.STATUSGROUPREMOVESTATUS(statusGroupId, statusTemplateId);
        const init = getRequestInit({accessToken, method: "DELETE"});
        await fetch(url, init).then(({response}) => {
            if (response.ok) {
                setInitialValueChanged(false);
                reFetchGroup();
            } else {
                setErrors(["Kunde inte spara statusmall!"]);
            }
        });
    }


    return (
        <>
            <PageHeader>
                <BackButton type="button" onClick={() => navigate(VIEWS.STATUSGROUPS)}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                    <span>Till statusmallar</span>
                </BackButton>
                <div>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    {formStateError && <ErrorMessage>Alla röda rutor måste fyllas i</ErrorMessage>}
                    <MarginButton type="button" onClick={() => setShowAddStatusModal(true)}>
                        <FontAwesomeIcon icon={faPlus}/>
                        <span>Lägg till status</span>
                    </MarginButton>
                    <MarginButton type="button" disabled={!initialValueChanged} onClick={() => sendData()}>
                        <FontAwesomeIcon icon={faFloppyDisk}/>
                        <span>Spara ändringar</span>
                    </MarginButton>
                </div>
            </PageHeader>
            <Row>
                {Object.entries(formState).length < 1
                    ?
                    <Col lg={{span: 4}}>
                        <Card>
                            <Card.Header>
                                Det finns ingen statusmall med id {statusGroupId}
                            </Card.Header>
                        </Card>
                    </Col>
                    :
                    <Col lg={{span: 4}}>
                        <Card>
                            <Card.Header><H2>Editering av statusmall</H2></Card.Header>
                            <Card.Body>
                                <b><p>Id: ({formState.statusGroupId || ""})</p></b>
                                <label>Namn</label>
                                <StyledInput
                                    type={"text"}
                                    value={formState.statusGroupName || ""}
                                    showError={!formState.statusGroupName}
                                    onChange={(e) => handleStatusGroupNameChange(e.target.value)}/>
                            </Card.Body>
                        </Card>
                    </Col>}
                <StatusTemplates statustemplates={formState.statusTemplates}
                                 handleRemoveStatus={handleRemoveStatus}
                                 handleStatusListChange={handleStatusListChange}
                                 handleRemoveStatusTemplateFromGroup={handleRemoveStatusTemplateFromGroup}
                />
                <Modal show={showAddStatusModal}
                       onHide={() => setShowAddStatusModal(false)}
                       dialogClassName={"modal-dialog modal-lg"}>
                    <AddStatusToStatusGroup handleAddStatus={handleAddStatus}
                                            statuses={formState?.statusTemplates?.map(x => x.statusTemplateId)}/>
                </Modal>
            </Row>
        </>
    );
}

export default StatusGroup;