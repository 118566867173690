import {
    LoadingView,
    PrimaryButton,
    SearchBarWithLabel,
    SecondaryButton,
    Table,
    TableBodyRow,
    Td,
    Th,
    useGet,
} from "@collabodoc/component-library";
import {faAngleLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generatePath, useNavigate} from "react-router-dom";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import usePagination from "../hooks/usePagination";
import {API_URLS, VIEWS} from "../URLS";
import {inputTemplates} from "../enums";
import {useState} from "react";
import {multiFilter} from "../helpers/sortFunctions";

const PageHeader = styled.section`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const MarginButton = styled(PrimaryButton)`
    svg:first-child {
        margin-right: 5px;
    }
`;

const IconButton = styled(SecondaryButton)`
    svg:first-child {
        margin-right: 5px;
    }
`;

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;


const StatusTemplatesView = () => {
    const navigate = useNavigate();
    const {response = [], isLoading} = useGet(
        API_URLS.STATUSTEMPLATES,
        "",
        true,
        {
            "cache-control": "no-cache",
        },
    );
    const [inputText, setInputText] = useState("");

    let filteredItems = multiFilter(response, inputText, [
        "phrase",
        "itemTemplateId",
        "groupTitle",
        "topLevelGroupTitle",
        "statusTemplateId"
    ]);

    const {page, pageCount, pageItems, resetPagination} =
        usePagination(filteredItems);

    let inputHandler = (e) => {
        resetPagination();
        //convert input text to lower case
        let lowerCase = e.toLowerCase();
        setInputText(lowerCase);
    };

    if (isLoading || !response) {
        return <LoadingView/>;
    }

    return (
        <>
            <PageHeader>
                <FlexWrapper>
                    <IconButton type="button" onClick={() => navigate(VIEWS.START)}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                        <span>Till startsida</span>
                    </IconButton>
                    <SearchBarWithLabel
                        placeholder={"Sök efter status..."}
                        id={"searchInput"}
                        handleChange={inputHandler}
                    />
                </FlexWrapper>
                <MarginButton type="button" onClick={() => navigate(VIEWS.NEWSTATUSTEMPLATE)}>
                    <FontAwesomeIcon icon={faPlus}/>
                    <span>Skapa ny statustemplate</span>
                </MarginButton>
            </PageHeader>
            <Table>
                <thead>
                <tr>
                    <Th width="5%">Id</Th>
                    <Th>Grupp</Th>
                    <Th>Fras</Th>
                    <Th width="20%">Förälder/Barn</Th>
                    <Th width="15%">Inputtyp</Th>
                </tr>
                </thead>
                <tbody>
                {pageItems.map((item) => (
                    <TableBodyRow
                        onClick={() =>
                            navigate(
                                generatePath(VIEWS.STATUSTEMPLATE, {itemId: item.statusTemplateId}),
                            )
                        }
                        key={item.itemTemplateId}
                    >
                        <Td>({item.statusTemplateId})</Td>
                        <Td>{item.groupTitle}</Td>
                        <Td>{item.phrase}</Td>
                        <Td>{item.parentStatusTemplateId ? "Barn" : "Förälder"}</Td>
                        <Td>
                            {item.inputTemplate &&
                                inputTemplates[item.inputTemplate.inputType]}
                        </Td>
                    </TableBodyRow>
                ))}
                </tbody>
            </Table>
            <Pagination pageCount={pageCount} currentPage={page}/>
        </>
    );
};

export default StatusTemplatesView;
