const oidcConfiguration = {
    authority: window.env.authority,
    client_id: window.env.clientId,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid profile role template_service_api offline_access',
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_only: true,
    automaticSilentRenew: true,
};

export default oidcConfiguration;
