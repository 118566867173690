import {
    Table,
    TableBodyRow,
    Td,
    H2,
    } from "@collabodoc/component-library";
import {Col, Card} from "react-bootstrap";
import {faTrashCan, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import React, {useState} from "react";

const StyledIcon = styled(FontAwesomeIcon)`
    margin-top: 10px;
`;

const ExpandStatus = styled.div`
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
`

const StyledTd = styled(Td)`
    vertical-align: top;
    text-align: right;
`;

const StyledTr = styled.tr`
    background-color: inherit !important;
`

const SymptomTypeStatusGroups = ({statusGroups = [], handleRemoveStatusGroup}) => {
    const [expandedItemId, setExpandedItemId] = useState(null);

    const handleExpand = (itemId) => {
        setExpandedItemId(itemId === expandedItemId ? null : itemId);
    };
    
    return (
        <Col lg={{span: 8, offset: 4}}>
            <Card>
                <Card.Header>
                    <H2>Statusmallar</H2>
                </Card.Header>
                <Card.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Namn</th>
                        </tr>
                        </thead>
                        <tbody>
                        {statusGroups.length < 1 ? (
                            <TableBodyRow>
                                <Td>Inga tillhörande statusmallar</Td>
                            </TableBodyRow>
                        ) : (
                            statusGroups.map((item) => (
                                <TableBodyRow key={item.id}>
                                    <Td>
                                        {item.id}
                                    </Td>
                                    <Td>
                                        {item.statusGroupName}
                                        <ExpandStatus key={item.id} onClick={() => handleExpand(item.id)}>
                                            <StyledIcon
                                                icon={expandedItemId === item.id ? faChevronUp : faChevronDown}/>
                                        </ExpandStatus>
                                        {expandedItemId === item.id &&
                                            <Col lg={8}>
                                                <table>
                                                    <thead>
                                                    <StyledTr>
                                                        <th>Grouptitle</th>
                                                        <th>Fras</th>
                                                    </StyledTr>
                                                    </thead>
                                                    <tbody>
                                                    {item.statusTemplates.map((status) => (
                                                        <StyledTr key={status.statusTemplateId}>
                                                            <td>{status.groupTitle}</td>
                                                            <td>{status.phrase}</td>
                                                        </StyledTr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        }
                                    </Td>
                                    <StyledTd
                                        onClick={(e) => handleRemoveStatusGroup(item.id)}
                                    >
                                        <StyledIcon icon={faTrashCan}/>
                                    </StyledTd>
                                </TableBodyRow>
                            ))
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    )
        ;
};

export default SymptomTypeStatusGroups;
