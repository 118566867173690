import React from "react";
import StatusTemplate from "./StatusTemplate";

const SubStatusTemplate = ({ item, level, reFetchItem, familyIds }) => {
  return (
    <>
      <StatusTemplate
        item={item}
        familyIds={familyIds}
        level={level}
        isChildQuestion={true}
        reFetchItem={reFetchItem}
      />
      {item.childStatusTemplates.map((item) => (
        <SubStatusTemplate
          key={Math.random()}
          item={item}
          familyIds={familyIds}
          level={level + 1}
          reFetchItem={reFetchItem}
        />
      ))}
    </>
  );
};

export default SubStatusTemplate;
