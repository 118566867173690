import {useGet, Table, TableBodyRow, Td, usePatch} from "@collabodoc/component-library";
import {API_URLS} from "../../URLS";
import {useState} from "react";
import {sortAscendingByKey, multiFilter} from "../../helpers/sortFunctions";
import styled from "styled-components";
import {useOidcAccessToken} from "@axa-fr/react-oidc";

const SearchBar = styled.input`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 5px;
  outline: none;
`

const AddQuestionToFamily = ({isChildQuestionRelation, question, reFetchQuestion, familyIds}) => {
  const {accessToken} = useOidcAccessToken();
  const {response: questions = []} = useGet(API_URLS.QUESTIONSWITHCHILDREN, "", true, {"cache-control": "no-cache"});
  const {doPatch} = usePatch(API_URLS.PATCHQUESTIONFAMILY(question.patientQuestionTemplateId), accessToken);
  const [searchText, setSearchText] = useState("");

  const nonFamilyQuestions = questions.filter((q) => {
    return !familyIds.includes(q.patientQuestionTemplateId) &&
        (!isChildQuestionRelation || (q.parentPatientQuestionTemplateId === null && q.symptomTypeTemplates.length < 1))
  })

  const filteredQuestions = multiFilter(nonFamilyQuestions, searchText, ['phrase', 'patientQuestionTemplateId']);
  const sortedQuestions = sortAscendingByKey(filteredQuestions, "phrase");


  const addQuestionHandler = (id, phrase, child = true) => {
    const data = child ? {
      childId: id
    } : {
      parentId: id
    };
    window.confirm(`Lägg till ${phrase} som ${child ? "barn" : "förälder"}?`) && doPatch(data).then(() => reFetchQuestion());
  }

  return (
      <>
        <SearchBar placeholder={"Sök efter fras eller id..."}
                   onChange={(e) => setSearchText(e.target.value)}/>
        <Table>
          <tbody>
          {sortedQuestions.length < 1 && searchText.length > 0 &&
              <TableBodyRow>
                <Td>Inga matchande frågor</Td>
              </TableBodyRow>
          }
          {(isChildQuestionRelation || (sortedQuestions.length > 0 && searchText.length > 0)) &&
              sortedQuestions.map(q => (
                  <TableBodyRow
                      onClick={() => addQuestionHandler(q.patientQuestionTemplateId, q.phrase, isChildQuestionRelation)}
                      key={q.patientQuestionTemplateId}>
                    <Td>Id:&nbsp;({q.patientQuestionTemplateId})</Td>
                    <Td>{q.phrase}</Td>
                  </TableBodyRow>
              ))}
          </tbody>
        </Table>
      </>
  );
}

export default AddQuestionToFamily;
