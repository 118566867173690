import {
  LoadingView,
  SecondaryButton,
  Table,
  TableBodyRow,
  Td,
  Th,
  useGet,
} from "@collabodoc/component-library";
import { generatePath, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import usePagination from "../hooks/usePagination";
import { API_URLS, VIEWS } from "../URLS";
import { symptomTypeSort } from "../enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const SymptomTypesView = () => {
  const { response = [], isLoading } = useGet(API_URLS.SYMPTOMTYPES, "", true, {
    "cache-control": "no-cache",
  });
  const { page, pageCount, pageItems } = usePagination(response);
  const navigate = useNavigate();

  const sortedPageItems = pageItems.sort((a, b) => {
    return b.symptomTypeTemplateId < a.symptomTypeTemplateId
      ? 1
      : b.symptomTypeTemplateId > a.symptomTypeTemplateId
      ? -1
      : 0;
  });

  if (isLoading || !response) {
    return <LoadingView />;
  }
  return (
    <>
      <PageHeader>
        <IconButton type="button" onClick={() => navigate(VIEWS.START)}>
          <FontAwesomeIcon icon={faAngleLeft} />
          <span>Till startsida</span>
        </IconButton>
      </PageHeader>
      <Table>
        <thead>
          <tr>
            <Th width="5%">Id</Th>
            <Th>Symptomtyp</Th>
            <Th width="20%" alignment={"right"}>
              Sort
            </Th>
          </tr>
        </thead>
        <tbody>
          {sortedPageItems.map((symptomtype) => (
            <TableBodyRow
              onClick={() =>
                navigate(
                  generatePath(VIEWS.SYMPTOMTYPE, {
                    symptomTypeId: symptomtype.symptomTypeTemplateId,
                  })
                )
              }
              key={symptomtype.symptomTypeTemplateId}
            >
              <Td>({symptomtype.symptomTypeTemplateId})</Td>
              <Td>{symptomtype.name}</Td>
              <Td alignment={"right"}>{symptomTypeSort[symptomtype.sort]}</Td>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
      <Pagination pageCount={pageCount} currentPage={page} />
    </>
  );
};

export default SymptomTypesView;
