import {useSearchParams} from "react-router-dom";


const usePagination = (items = [], showDefault = 20) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = Math.max(Number(searchParams.get("page")) || 1, 1);
    const show = Math.max(Number(searchParams.get("show")) || showDefault, showDefault);
    const pageCount = Math.ceil(items.length / show);
    const pageItems = items.slice((page - 1) * show, page * show);

    const resetPagination = () => {
        searchParams.delete('page');
        setSearchParams(searchParams);
    }
    
    return {
        page,
        pageCount,
        pageItems,
        resetPagination
    }
}

export default usePagination;
