import React from "react";
import { SecondaryButton } from "@collabodoc/component-library";
import { VIEWS } from "../../URLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ItemTemplate from "./ItemTemplate";

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const NewItem = () => {
  const navigate = useNavigate();

  return (
    <div className="marginBottom">
      <PageHeader>
        <IconButton type="button" onClick={() => navigate(VIEWS.ITEMS)}>
          <FontAwesomeIcon icon={faAngleLeft} />
          <span>Till items</span>
        </IconButton>
      </PageHeader>
      <ItemTemplate isNewItem={true} isChildItem={true} />
    </div>
  );
};

export default NewItem;
