import React from "react";
import ItemTemplate from "./ItemTemplate";

const SubItemTemplate = ({ item, level, reFetchItem, familyIds }) => {
  return (
    <>
      <ItemTemplate
        item={item}
        familyIds={familyIds}
        level={level}
        isChildQuestion={true}
        reFetchItem={reFetchItem}
      />
      {item.childItemTemplates.map((item) => (
        <SubItemTemplate
          key={Math.random()}
          item={item}
          familyIds={familyIds}
          level={level + 1}
          reFetchItem={reFetchItem}
        />
      ))}
    </>
  );
};

export default SubItemTemplate;
