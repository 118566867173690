import {
  DropDown,
  LoadingView,
  PrimaryButton,
  SearchBarWithLabel,
  SecondaryButton,
  Table,
  TableBodyRow,
  Td,
  Th,
  useGet,
} from "@collabodoc/component-library";
import { faAngleLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, generatePath } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import usePagination from "../hooks/usePagination";
import { API_URLS, VIEWS } from "../URLS";
import { inputTemplates } from "../enums";
import { useState } from "react";
import { multiFilter, sortAscendingByKey } from "../helpers/sortFunctions";

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const MarginButton = styled(PrimaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DropDownWrapper = styled.div`
  width: 160px;
`;

const getSymptomTypeColumnValue = (symptomTypeTemplates = []) => {
  if (symptomTypeTemplates.length === 0) {
    return "Ingen";
  }
  if (symptomTypeTemplates.length === 1) {
    return symptomTypeTemplates[0].name;
  }
  return `Flera (${symptomTypeTemplates.length})`;
};

const QuestionsView = () => {
  const navigate = useNavigate();
  const { response = [], isLoading } = useGet(
    API_URLS.QUESTIONSWITHCHILDREN,
    "",
    true,
    { "cache-control": "no-cache" }
  );
  const [inputText, setInputText] = useState("");
  const [dropdownValue, setDropdownValue] = useState(null);

  let filteredQuestions = multiFilter(response, inputText, [
    "phrase",
    "patientQuestionTemplateId",
  ]);

  if (dropdownValue)
    filteredQuestions = filteredQuestions.filter(
      (question) =>
        question.inputTemplate &&
        String(question.inputTemplate.inputType) === dropdownValue
    );

  const sortedQuestions = filteredQuestions.sort((a, b) => {
    return b.patientQuestionTemplateId < a.patientQuestionTemplateId
      ? 1
      : b.patientQuestionTemplateId > a.patientQuestionTemplateId
      ? -1
      : 0;
  });

  const { page, pageCount, pageItems, resetPagination } =
    usePagination(sortedQuestions);

  let inputHandler = (e) => {
    resetPagination();
    //convert input text to lower case
    let lowerCase = e.toLowerCase();
    setInputText(lowerCase);
  };

  const InputTypeOptions = Object.entries(inputTemplates).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  InputTypeOptions.push({ id: null, text: "Alla" });

  const sortedOptions = sortAscendingByKey(InputTypeOptions, "text");

  if (isLoading || !response) {
    return <LoadingView />;
  }

  return (
    <>
      <PageHeader>
        <FlexWrapper>
          <IconButton type="button" onClick={() => navigate(VIEWS.START)}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <span>Till startsida</span>
          </IconButton>
          <SearchBarWithLabel
            placeholder={"Sök efter fråga..."}
            id={"searchInput"}
            handleChange={inputHandler}
          />
          <DropDownWrapper>
            <DropDown
              options={sortedOptions}
              placeholder={"Frågetyp"}
              handler={(e) => setDropdownValue(e)}
            ></DropDown>
          </DropDownWrapper>
        </FlexWrapper>
        <MarginButton type="button" onClick={() => navigate(VIEWS.NEWQUESTION)}>
          <FontAwesomeIcon icon={faPlus} />
          <span>Skapa ny fråga</span>
        </MarginButton>
      </PageHeader>
      <Table>
        <thead>
          <tr>
            <Th width="5%">Id</Th>
            <Th>Fråga</Th>
            <Th width="20%">Förälder/Barn</Th>
            <Th width="15%">Frågetyp</Th>
            <Th width="20%" alignment={"right"}>
              Symptomtyp(er)
            </Th>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((question) => (
            <TableBodyRow
              onClick={() =>
                navigate(
                  generatePath(VIEWS.QUESTION, {
                    questionId: question.patientQuestionTemplateId,
                  })
                )
              }
              key={question.patientQuestionTemplateId}
            >
              <Td>({question.patientQuestionTemplateId})</Td>
              <Td>{question.phrase}</Td>
              <Td>
                {question.parentPatientQuestionTemplateId ? "Barn" : "Förälder"}
              </Td>
              <Td>
                {question.inputTemplate &&
                  inputTemplates[question.inputTemplate.inputType]}
              </Td>
              <Td
                alignment={"right"}
                title={question.symptomTypeTemplates
                  .map((t) => t.name)
                  .join(", ")}
              >
                {getSymptomTypeColumnValue(question.symptomTypeTemplates)}
              </Td>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
      <Pagination pageCount={pageCount} currentPage={page} />
    </>
  );
};

export default QuestionsView;
