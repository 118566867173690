import {
  DropDown,
  TextInputWithLabelAndError,
  Input,
  CheckboxWithLabel,
  PrimaryButton,
  SecondaryButton,
  H2,
  usePut,
  usePost,
  H4,
} from "@collabodoc/component-library";
import { targetGroups, inputTemplateIdEnum } from "../../enums";
import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import AddQuestionToFamily from "./AddQuestionToFamily";
import { API_URLS, VIEWS } from "../../URLS";
import { useNavigate } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 5px;

  svg:first-child {
    margin-right: 5px;
  }
`;

const IconButton = styled(PrimaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const StyledCol = styled(Col)`
  margin-left: ${(props) => props.level}0%;
`;

const StyledH4 = styled(H4)`
  display: flex;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledCardHeader = styled(Card.Header)`
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  border: ${(props) => props.showError && "solid 2px red"};

  &:focus {
    border: ${(props) => props.showError && "solid 2px red"};
  }

  /*Remove arrows from number input*/

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const DropDownErrorWrapper = styled.div`
  border: ${(props) => props.showError && "solid 2px red"};
`;

const StyledCard = styled(Card)`
  margin-top: 15px;
`;

const FormErrorMessage = styled.span`
  color: red;
`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Question = ({
  question = [],
  familyIds,
  level,
  isNewQuestion = false,
  isChildQuestion = false,
  reFetchQuestion,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { questionId } = useParams();
  const navigate = useNavigate();
  const { doPut } = usePut(
    API_URLS.PATCHQUESTION(question.patientQuestionTemplateId),
    accessToken,
  );
  const { doPut: removeParentId } = usePut(
    API_URLS.REMOVEPARENTQUESTIONID(question.patientQuestionTemplateId),
    accessToken,
  );
  const { doPost } = usePost(API_URLS.POSTQUESTION, accessToken);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showRelationModal, setShowRelationModal] = useState(false);
  const [isChildQuestionRelation, setIsChildQuestionRelation] = useState(null);
  const [questionState, setQuestionState] = useState({});
  const [formStateError, setFormStateError] = useState(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(true);
  const [initialValueChanged, setInitialValueChanged] = useState(false);

  useEffect(() => {
    !isNewQuestion && setQuestionState(question);
  }, [question, setQuestionState, isNewQuestion]);

  useEffect(() => {
    isNewQuestion || questionId === String(question.patientQuestionTemplateId)
      ? setIsCollapsed(false)
      : setIsCollapsed(true);
  }, [isNewQuestion, questionId, question.patientQuestionTemplateId]);

  useEffect(() => {
    if (
      questionState.targetGroup == null ||
      (questionState.multiCulturePhrase &&
        !questionState.multiCulturePhrase["sv-SE"])
    ) {
      setFormStateError(true);
      if (initialValueChanged) {
        setIsDisabledSaveButton(true);
      }
    } else {
      setFormStateError(false);
      if (initialValueChanged) {
        setIsDisabledSaveButton(false);
      }
    }
  }, [questionState, initialValueChanged]);

  const handleQuestionChange = (key, value) => {
    setInitialValueChanged(true);

    if (key === "multiCulturePhrase") {
      const phrase = {
        multiCulturePhrase: {
          ...questionState[key],
          ...value,
        },
      };
      setQuestionState((prevState) => ({
        ...prevState,
        ...phrase,
      }));
    } else if (key === "multiCultureGroupTitle") {
      const groupTitle = {
        multiCultureGroupTitle: {
          ...questionState[key],
          ...value,
        },
      };
      setQuestionState((prevState) => ({
        ...prevState,
        ...groupTitle,
      }));
    } else if (key === "inputTemplate") {
      const inputTemp = {
        inputTemplate: {
          ...questionState[key],
          ...value,
        },
      };
      setQuestionState((prevState) => ({
        ...prevState,
        ...inputTemp,
      }));
    } else {
      setQuestionState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const InputTypeOptions = Object.entries(inputTemplateIdEnum).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  const TargetGroupOptions = Object.entries(targetGroups).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  const openRelationModal = () => {
    setShowRelationModal(true);
  };

  const openChildModalHandler = () => {
    setIsChildQuestionRelation(true);
  };

  const openParentModalHandler = () => {
    setIsChildQuestionRelation(false);
  };

  const postQuestion = () => {
    setIsDisabledSaveButton(true);

    doPost({
      phrases: Object.entries(questionState.multiCulturePhrase).map(
        ([key, value]) => ({
          culture: key,
          phrase: value,
          groupTitle:
            questionState.multiCultureGroupTitle &&
            questionState.multiCultureGroupTitle[key],
        }),
      ),
      journalYesPhrase: questionState.journalYesPhrase,
      journalNoPhrase: questionState.journalNoPhrase,
      targetGroup: questionState.targetGroup,
      mandatory: questionState.mandatory || false,
      inputTemplateId:
        (questionState.inputTemplate &&
          questionState.inputTemplate.inputTemplateId) ||
        null,
      childQuestionIndex: questionState.childQuestionIndex,
    }).then(() => setQuestionState([]));
  };

  const patchQuestion = () => {
    setIsDisabledSaveButton(true);

    doPut({
      phrases: Object.entries(questionState.multiCulturePhrase).map(
        ([key, value]) => ({
          culture: key,
          phrase: value,
          groupTitle:
            questionState.multiCultureGroupTitle &&
            questionState.multiCultureGroupTitle[key],
        }),
      ),
      journalYesPhrase: questionState.journalYesPhrase,
      journalNoPhrase: questionState.journalNoPhrase,
      targetGroup: questionState.targetGroup,
      mandatory: questionState.mandatory || false,
      inputTemplateId:
        (questionState.inputTemplate &&
          questionState.inputTemplate.inputTemplateId) ||
        null,
      childQuestionIndex: questionState.childQuestionIndex,
      parentId: questionState.parentPatientQuestionTemplateId,
    });
  };

  const removeParentQuestion = () => {
    window.confirm("Kapa släktträd?") &&
      removeParentId({
        parentId: null,
      }).then(() => reFetchQuestion());
  };

  return (
    <Row>
      <StyledCol lg={{ span: 4 }} level={level}>
        <Card>
          <StyledCardHeader onClick={() => setIsCollapsed(!isCollapsed)}>
            {isNewQuestion ? (
              <StyledH4>
                Ny fråga{" "}
                {isCollapsed ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </StyledH4>
            ) : (
              <StyledH4>
                <span>
                  {questionState.phrase || ""} (id:{" "}
                  {questionState.patientQuestionTemplateId || ""})
                </span>
                {isCollapsed ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </StyledH4>
            )}
          </StyledCardHeader>
          {!isCollapsed && (
            <Card.Body>
              <StyledWrapper>
                <div>
                  {!isNewQuestion && level < 3 && (
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => openRelationModal()}
                    >
                      <span>Skapa relation</span>
                    </StyledPrimaryButton>
                  )}
                  {!isNewQuestion && level > 0 && (
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => removeParentQuestion()}
                    >
                      <span>Kapa släktträd</span>
                    </StyledPrimaryButton>
                  )}
                </div>
                {isNewQuestion ? (
                  <IconButton
                    type="button"
                    disabled={isDisabledSaveButton}
                    onClick={() => postQuestion()}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    <span>Spara</span>
                  </IconButton>
                ) : (
                  <IconButton
                    type="button"
                    disabled={isDisabledSaveButton}
                    onClick={() => patchQuestion()}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    <span>Spara</span>
                  </IconButton>
                )}
              </StyledWrapper>
              {formStateError && (
                <FormErrorMessage>
                  Alla röda rutor måste fyllas i
                </FormErrorMessage>
              )}
              {!isNewQuestion && (
                <div>
                  <label>
                    PatientQuestionId:{" "}
                    {questionState.patientQuestionTemplateId || ""}
                  </label>
                </div>
              )}
              {isChildQuestion && !isNewQuestion && (
                <div>
                  <label>
                    ParentPatientQuestionId:{" "}
                    {questionState.parentPatientQuestionTemplateId}
                  </label>
                </div>
              )}
              <div>
                <label htmlFor={"sv-SE"}>Fras (svenska)</label>
                <StyledInput
                  id={"sv-SE"}
                  type={"text"}
                  value={
                    questionState.multiCulturePhrase
                      ? questionState.multiCulturePhrase["sv-SE"]
                      : ""
                  }
                  showError={
                    !questionState.multiCulturePhrase ||
                    (questionState.multiCulturePhrase &&
                      !questionState.multiCulturePhrase["sv-SE"])
                  }
                  onChange={(e) =>
                    handleQuestionChange("multiCulturePhrase", {
                      "sv-SE": e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <TextInputWithLabelAndError
                  value={
                    questionState.multiCulturePhrase
                      ? questionState.multiCulturePhrase["en-US"]
                      : ""
                  }
                  handleChange={(value) =>
                    handleQuestionChange("multiCulturePhrase", {
                      "en-US": value,
                    })
                  }
                  label={"Fras (engelska)"}
                />
              </div>
              <div>
                <TextInputWithLabelAndError
                  value={
                    questionState.multiCulturePhrase
                      ? questionState.multiCulturePhrase["de-DE"]
                      : ""
                  }
                  handleChange={(value) =>
                    handleQuestionChange("multiCulturePhrase", {
                      "de-DE": value,
                    })
                  }
                  label={"Fras (tyska)"}
                />
              </div>
              {(!isChildQuestion || isNewQuestion) && (
                <>
                  <div>
                    <TextInputWithLabelAndError
                      value={
                        (questionState.multiCultureGroupTitle &&
                          questionState.multiCultureGroupTitle["sv-SE"]) ||
                        ""
                      }
                      handleChange={(value) =>
                        handleQuestionChange("multiCultureGroupTitle", {
                          "sv-SE": value,
                        })
                      }
                      label={"GroupTitle (svenska)"}
                    />
                  </div>
                  <div>
                    <TextInputWithLabelAndError
                      value={
                        (questionState.multiCultureGroupTitle &&
                          questionState.multiCultureGroupTitle["en-US"]) ||
                        ""
                      }
                      handleChange={(value) =>
                        handleQuestionChange("multiCultureGroupTitle", {
                          "en-US": value,
                        })
                      }
                      label={"GroupTitle (engelska)"}
                    />
                  </div>
                  <div>
                    <TextInputWithLabelAndError
                      value={
                        (questionState.multiCultureGroupTitle &&
                          questionState.multiCultureGroupTitle["de-DE"]) ||
                        ""
                      }
                      handleChange={(value) =>
                        handleQuestionChange("multiCultureGroupTitle", {
                          "de-DE": value,
                        })
                      }
                      label={"GroupTitle (tyska)"}
                    />
                  </div>
                </>
              )}
              <div>
                <TextInputWithLabelAndError
                  value={questionState.journalYesPhrase || ""}
                  handleChange={(value) =>
                    handleQuestionChange("journalYesPhrase", value)
                  }
                  label={"Journalfras vid ja"}
                />
              </div>
              <div>
                <TextInputWithLabelAndError
                  value={questionState.journalNoPhrase || ""}
                  handleChange={(value) =>
                    handleQuestionChange("journalNoPhrase", value)
                  }
                  label={"Journalfras vid nej"}
                />
              </div>
              <div>
                <CheckboxWithLabel
                  label={"Mandatory?"}
                  onChange={() =>
                    handleQuestionChange("mandatory", !questionState.mandatory)
                  }
                  checked={questionState.mandatory || false}
                />
              </div>
              <div>
                <label>
                  InputTemplateId: (
                  {questionState.inputTemplate
                    ? questionState.inputTemplate.inputTemplateId
                    : ""}
                  )
                </label>
                <DropDown
                  options={InputTypeOptions}
                  selected={
                    inputTemplateIdEnum[
                      questionState.inputTemplate &&
                        questionState.inputTemplate.inputTemplateId
                    ] || "Ej specificerat"
                  }
                  handler={(e) =>
                    handleQuestionChange("inputTemplate", {
                      inputTemplateId: Number(e),
                    })
                  }
                ></DropDown>
              </div>
              <div>
                <label>TargetGroup:</label>
                <DropDownErrorWrapper
                  showError={questionState.targetGroup == null}
                >
                  <DropDown
                    options={TargetGroupOptions}
                    selected={targetGroups[questionState.targetGroup] || ""}
                    handler={(e) =>
                      handleQuestionChange("targetGroup", Number(e))
                    }
                  ></DropDown>
                </DropDownErrorWrapper>
              </div>
              {isChildQuestion && (
                <div>
                  <label>ChildQuestionIndex: (sickleave)</label>
                  <StyledInput
                    type={"number"}
                    value={questionState.childQuestionIndex || ""}
                    onChange={(e) =>
                      handleQuestionChange(
                        "childQuestionIndex",
                        e.target.valueAsNumber,
                      )
                    }
                  />
                </div>
              )}
            </Card.Body>
          )}
        </Card>
      </StyledCol>

      <Modal
        show={showRelationModal}
        onHide={() => setShowRelationModal(false)}
        dialogClassName={"modal-dialog modal-lg"}
      >
        <Col lg={{ span: 12 }}>
          <StyledCard>
            <Card.Header>
              <H2>Lägg till fråga</H2>
            </Card.Header>
            <StyledCardBody>
              {level < 3 && isChildQuestion ? (
                <>
                  <StyledWrapper>
                    <div />
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => navigate(VIEWS.NEWQUESTION)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Skapa ny fråga</span>
                    </StyledPrimaryButton>
                  </StyledWrapper>
                  <AddQuestionToFamily
                    question={questionState}
                    familyIds={familyIds}
                    isChildQuestionRelation={true}
                    reFetchQuestion={reFetchQuestion}
                  />
                </>
              ) : (
                <>
                  <StyledWrapper>
                    <div>
                      {!isChildQuestionRelation ? (
                        <>
                          <StyledSecondaryButton
                            type="button"
                            onClick={() => openChildModalHandler()}
                          >
                            <span>Barnfråga</span>
                          </StyledSecondaryButton>
                          <StyledPrimaryButton
                            type="button"
                            onClick={() => openParentModalHandler()}
                          >
                            <span>Förälderfråga</span>
                          </StyledPrimaryButton>
                        </>
                      ) : (
                        <>
                          <StyledPrimaryButton
                            type="button"
                            onClick={() => openChildModalHandler()}
                          >
                            <span>Barnfråga</span>
                          </StyledPrimaryButton>
                          <StyledSecondaryButton
                            type="button"
                            onClick={() => openParentModalHandler()}
                          >
                            <span>Förälderfråga</span>
                          </StyledSecondaryButton>
                        </>
                      )}
                    </div>
                    <StyledPrimaryButton
                      type="button"
                      onClick={() => navigate(VIEWS.NEWQUESTION)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Skapa ny fråga</span>
                    </StyledPrimaryButton>
                  </StyledWrapper>
                  <AddQuestionToFamily
                    question={questionState}
                    familyIds={familyIds}
                    isChildQuestionRelation={isChildQuestionRelation}
                    reFetchQuestion={reFetchQuestion}
                  />
                </>
              )}
            </StyledCardBody>
          </StyledCard>
        </Col>
      </Modal>
    </Row>
  );
};

export default Question;
