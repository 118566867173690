import {
    collabodocTheme,
    LoadingView,
    NavBar,
} from "@collabodoc/component-library";
import {Container} from "react-bootstrap";
import {Link, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import logo from "./images/collabodoc_light.png";
import QuestionsView from "./views/QuestionsView";
import PatientQuestion from "./views/patientquestion/PatientQuestion";
import NewPatientQuestion from "./views/patientquestion/NewPatientQuestion";
import SymptomTypesView from "./views/SymptomTypesView";
import SymptomType from "./views/symptomtype/SymptomType";
import {VIEWS} from "./URLS";
import {useNavigate} from "react-router-dom";
import {OidcProvider, OidcSecure} from "@axa-fr/react-oidc";
import oidcConfiguration from "./OidcConfiguration";
import ItemsView from "./views/ItemsView";
import ItemView from "./views/item/ItemView";
import NewItem from "./views/item/NewItem";
import StatusGroupsView from "./views/StatusGroupsView";
import StatusGroup from "./views/statusgroup/StatusGroup";
import StatusTemplatesView from "./views/StatusTemplatesView";
import StatusTemplateView from "./views/statustemplate/StatusTemplateView";
import NewStatusTemplate from "./views/statustemplate/NewStatusTemplate";

const IndexView = () => {
    return (
        <>
            <p>
                <Link to={VIEWS.SYMPTOMTYPES}>SymptomTyper</Link>
            </p>
            <p>
                <Link to={VIEWS.QUESTIONS}>Patientfrågor</Link>
            </p>
            <p>
                <Link to={VIEWS.STATUSTEMPLATES}>Statusar</Link>
            </p>
            <p>
                <Link to={VIEWS.STATUSGROUPS}>Statusmallar</Link>
            </p>
            <p>
                <Link to={VIEWS.ITEMS}>Items</Link>
            </p>
        </>
    );
};

const App = () => {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={collabodocTheme}>
            <OidcProvider
                configuration={oidcConfiguration}
                authenticatingComponent={LoadingView}
                callbackSuccessComponent={LoadingView}
                loadingComponent={LoadingView}
            >
                <OidcSecure>
                    <NavBar logo={logo} logohandler={() => navigate(VIEWS.START)}/>
                    <Container fluid>
                        <Routes>
                            <Route path={VIEWS.START} element={<IndexView/>}/>
                            <Route path={VIEWS.QUESTIONS} element={<QuestionsView/>}/>
                            <Route path={VIEWS.QUESTION} element={<PatientQuestion/>}/>
                            <Route
                                path={VIEWS.NEWQUESTION}
                                element={<NewPatientQuestion/>}
                            />
                            <Route path={VIEWS.SYMPTOMTYPES} element={<SymptomTypesView/>}/>
                            <Route path={VIEWS.SYMPTOMTYPE} element={<SymptomType/>}/>
                            <Route path={VIEWS.ITEMS} element={<ItemsView/>}/>
                            <Route path={VIEWS.ITEM} element={<ItemView/>}/>
                            <Route path={VIEWS.NEWITEM} element={<NewItem/>}/>
                            <Route path={VIEWS.STATUSTEMPLATES} element={<StatusTemplatesView/>}/>
                            <Route path={VIEWS.STATUSTEMPLATE} element={<StatusTemplateView/>}/>
                            <Route path={VIEWS.NEWSTATUSTEMPLATE} element={<NewStatusTemplate/>}/>
                            <Route path={VIEWS.STATUSGROUPS} element={<StatusGroupsView/>}/>
                            <Route path={VIEWS.STATUSGROUP} element={<StatusGroup/>}/>
                        </Routes>
                    </Container>
                </OidcSecure>
            </OidcProvider>
        </ThemeProvider>
    );
};
export default App;
