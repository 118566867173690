import {
  CheckboxWithLabel,
  DropDown,
  LoadingView,
  PrimaryButton,
  SearchBarWithLabel,
  SecondaryButton,
  Table,
  TableBodyRow,
  Td,
  Th,
  useGet,
} from "@collabodoc/component-library";
import { faAngleLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, generatePath } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import usePagination from "../hooks/usePagination";
import { API_URLS, VIEWS } from "../URLS";
import { inputTemplates, itemTypes } from "../enums";
import { useState } from "react";
import { multiFilter, sortAscendingByKey } from "../helpers/sortFunctions";

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const MarginButton = styled(PrimaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DropDownWrapper = styled.div`
  width: 160px;
`;

const getSymptomTypeColumnValue = (symptomTypeTemplates = []) => {
  if (symptomTypeTemplates.length === 0) {
    return "Ingen";
  }
  if (symptomTypeTemplates.length === 1) {
    return symptomTypeTemplates[0].name;
  }
  return `Flera (${symptomTypeTemplates.length})`;
};

const ItemsView = () => {
  const navigate = useNavigate();
  const { response = [], isLoading } = useGet(
    API_URLS.ITEMSWITHCHILDREN,
    "",
    true,
    {
      "cache-control": "no-cache",
    },
  );
  const [inputText, setInputText] = useState("");
  const [dropdownItemTypeValue, setDropdownItemTypeValue] = useState(null);
  const [dropdownInputTypeValue, setDropdownInputTypeValue] = useState(null);
  const [showOnlyParents, setShowOnlyParents] = useState(false);

  let filteredItems = multiFilter(response, inputText, [
    "phrase",
    "itemTemplateId",
    "groupTitle",
    "topLevelGroupTitle",
  ]);

  if (dropdownItemTypeValue)
    filteredItems = filteredItems.filter(
      (item) => String(item.itemType) === dropdownItemTypeValue,
    );
  if (dropdownInputTypeValue)
    filteredItems = filteredItems.filter(
      (item) => String(item.inputTemplate.inputType) === dropdownInputTypeValue,
    );
  if (showOnlyParents)
    filteredItems = filteredItems.filter(
      (item) => item.parentItemTemplateId === null,
    );

  const { page, pageCount, pageItems, resetPagination } =
    usePagination(filteredItems);

  let inputHandler = (e) => {
    resetPagination();
    //convert input text to lower case
    let lowerCase = e.toLowerCase();
    setInputText(lowerCase);
  };

  const itemTypeOptions = Object.entries(itemTypes).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  const inputTypeOptions = Object.entries(inputTemplates).map((x) => {
    return {
      id: x[0],
      text: x[1],
    };
  });

  itemTypeOptions.push({ id: null, text: "Alla" });
  inputTypeOptions.push({ id: null, text: "Alla" });

  const sortedItemOptions = sortAscendingByKey(itemTypeOptions, "text");
  const sortedInputOptions = sortAscendingByKey(inputTypeOptions, "text");

  if (isLoading || !response) {
    return <LoadingView />;
  }

  return (
    <>
      <PageHeader>
        <FlexWrapper>
          <IconButton type="button" onClick={() => navigate(VIEWS.START)}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <span>Till startsida</span>
          </IconButton>
          <SearchBarWithLabel
            placeholder={"Sök efter item..."}
            id={"searchInput"}
            handleChange={inputHandler}
          />
          <DropDownWrapper>
            <DropDown
              options={sortedItemOptions}
              placeholder={"Itemtype"}
              handler={(e) => setDropdownItemTypeValue(e)}
            ></DropDown>
          </DropDownWrapper>
          <DropDownWrapper>
            <DropDown
              options={sortedInputOptions}
              placeholder={"Inputtype"}
              handler={(e) => setDropdownInputTypeValue(e)}
            ></DropDown>
          </DropDownWrapper>
          <CheckboxWithLabel
            label={"Visa bara föräldrar"}
            onChange={() => setShowOnlyParents(!showOnlyParents)}
          />
        </FlexWrapper>
        <MarginButton type="button" onClick={() => navigate(VIEWS.NEWITEM)}>
          <FontAwesomeIcon icon={faPlus} />
          <span>Skapa ny item</span>
        </MarginButton>
      </PageHeader>
      <Table>
        <thead>
          <tr>
            <Th width="5%">Id</Th>
            <Th>Huvudgrupp</Th>
            <Th>Grupp</Th>
            <Th>Fras</Th>
            <Th width="20%">Förälder/Barn</Th>
            <Th width="15%">Itemtyp</Th>
            <Th width="15%">Inputtyp</Th>
            <Th width="20%" alignment={"right"}>
              Symptomtyp(er)
            </Th>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((item) => (
            <TableBodyRow
              onClick={() =>
                navigate(
                  generatePath(VIEWS.ITEM, { itemId: item.itemTemplateId }),
                )
              }
              key={item.itemTemplateId}
            >
              <Td>({item.itemTemplateId})</Td>
              <Td>{item.topLevelGroupTitle}</Td>
              <Td>{item.groupTitle}</Td>
              <Td>{item.phrase}</Td>
              <Td>{item.parentItemTemplateId ? "Barn" : "Förälder"}</Td>
              <Td>{item.itemType && itemTypes[item.itemType]}</Td>
              <Td>
                {item.inputTemplate &&
                  inputTemplates[item.inputTemplate.inputType]}
              </Td>
              <Td
                alignment={"right"}
                title={item.symptomTypeTemplates.map((t) => t.name).join(", ")}
              >
                {getSymptomTypeColumnValue(item.symptomTypeTemplates)}
              </Td>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
      <Pagination pageCount={pageCount} currentPage={page} />
    </>
  );
};

export default ItemsView;
