import React from "react";
import { Card, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { H1, SecondaryButton, useGet } from "@collabodoc/component-library";
import { API_URLS, VIEWS } from "../../URLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import ItemTemplate from "./ItemTemplate";
import SubItemTemplate from "./SubItemTemplate";

const IconButton = styled(SecondaryButton)`
  svg:first-child {
    margin-right: 5px;
  }
`;

const PageHeader = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const getChildIds = (itemTemplate) => {
  const ids = [];
  if (itemTemplate) {
    ids.push(itemTemplate.itemTemplateId);
    itemTemplate.childItemTemplates.forEach((childItem) => {
      ids.push(...getChildIds(childItem));
    });
  }
  return ids;
};

const ItemView = () => {
  const { accessToken } = useOidcAccessToken();
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { response: itemTemplateGroup = [], doGet: reFetchItem } = useGet(
    API_URLS.ITEMWITHFAMILY(itemId),
    accessToken,
    true,
    { "cache-control": "no-cache" }
  );

  return (
    <>
      {itemTemplateGroup.length ? (
        <>
          <PageHeader>
            <IconButton type="button" onClick={() => navigate(VIEWS.ITEMS)}>
              <FontAwesomeIcon icon={faAngleLeft} />
              <span>Till itemlista</span>
            </IconButton>
            <H1>{itemTemplateGroup[0].groupTitle}</H1>
            <p></p>
          </PageHeader>
          {itemTemplateGroup.map((ig, index) => (
            <ItemTemplateGroup
              group={ig}
              key={index}
              reFetchItem={reFetchItem}
            />
          ))}
        </>
      ) : (
        <Col lg={{ span: 4 }}>
          <Card>
            <Card.Header>Det finns ingen item med id {itemId}</Card.Header>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ItemView;

const ItemTemplateGroup = ({ group, reFetchItem }) => {
  const familyIds = getChildIds(group);
  return (
    <>
      <ItemTemplate
        item={group}
        familyIds={familyIds}
        level={0}
        reFetchItem={reFetchItem}
        isChildItem={group.parentItemTemplateId !== null}
      />
      {group.childItemTemplates.map((item) => (
        <SubItemTemplate
          key={Math.random()}
          item={item}
          familyIds={familyIds}
          reFetchItem={reFetchItem}
          level={1}
        />
      ))}
    </>
  );
};
