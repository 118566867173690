const API_URLS = {
  SYMPTOMTYPES: `/api/template/symptomtype`,
  SYMPTOMTYPE: (id) => `/api/template/symptomtype/${id}`,
  PATCHSMPTOMTYPE: (id) => `/api/symptomtype/${id}/edit`,
  COPYSYMPTOMTYPE: (id) => `/api/symptomtype/${id}/copy`,
  STATUSGROUPONSYMPTOMTYPE: (symptomTypeId) => `/api/symptomtype/${symptomTypeId}/statusgroup`,
  //questions
  QUESTIONS: `/api/question`,
  QUESTIONSWITHCHILDREN: `/api/question?isFlattened=true&excludeChildrenOnRootLevel=false`,
  QUESTIONWITHFAMILY: (id) => `/api/question/${id}/family`,
  SYMPTOMTYPEQUESTIONS: (id) => `/api/template/symptomform/${id}/question`,
  PATCHQUESTION: (id) => `/api/question/${id}/edit`,
  PATCHQUESTIONFAMILY: (id) => `/api/question/${id}`,
  POSTQUESTION: `/api/question`,
  REMOVEPARENTQUESTIONID: (id) => `/api/question/${id}/parent`,
  //items
  SYMPTOMTYPEITEMS: (id) => `/api/template/symptomform/${id}/item`,
  ITEMSWITHCHILDREN: `/api/item?isFlattened=true`,
  ITEMWITHFAMILY: (id) => `/api/item/${id}/family`,
  ITEMS: `/api/item`,
  REMOVEPARENTITEMID: (id) => `/api/item/${id}/parent`,
  PATCHITEMFAMILY: (id) => `/api/item/${id}`,
  PATCHITEM: (id, editGroupTitleForGroup) =>
    `/api/item/${id}/edit?editGroupTitleForGroup=${editGroupTitleForGroup}`,
  POSTITEM: `/api/item`,
  //status
  STATUSTEMPLATES:  `/api/status`,
  STATUSTEMPLATESWITHCHILDREN: `/api/status?isFlattened=true`,
  STATUSWITHFAMILY: (id) => `/api/status/${id}/family`,
  STATUSGROUPS: `/api/status/statusgroup`,
  STATUSGROUP: (id) =>
      `/api/status/statusgroup/${id}`,
  STATUSGROUPREMOVESTATUS: (groupId, statusId) =>
      `/api/status/${statusId}/statusgroup/${groupId}`,
  STATUS: `/api/status`,
  REMOVEPARENTSTATUSID: (id) => `/api/status/${id}/parent`,
  PATCHSTATUSFAMILY: (id) => `/api/status/${id}`,
  PATCHSTATUS: (id) =>
      `/api/status/${id}/edit`,
  POSTSTATUS: `/api/status`,
};

const VIEWS = {
  START: "/",
  QUESTIONS: "/questions",
  QUESTION: "/questions/:questionId",
  NEWQUESTION: "/questions/newQuestion",
  SYMPTOMTYPES: "/symptomtypes",
  SYMPTOMTYPE: "/symptomtypes/:symptomTypeId",
  ITEMS: "/items",
  ITEM: "/items/:itemId",
  NEWITEM: "/items/newItem",
  STATUSTEMPLATES: "/statustemplates",
  NEWSTATUSTEMPLATE: "/statustemplates/newstatustemplate",
  STATUSTEMPLATE: "/statustemplate/:itemId",
  STATUSGROUPS: "/statusgroups",
  STATUSGROUP: "/statusgroups/:statusGroupId"

};

export { API_URLS, VIEWS };
