import {
  useGet,
  Table,
  TableBodyRow,
  Td,
  usePatch,
} from "@collabodoc/component-library";
import { API_URLS } from "../../URLS";
import { useState } from "react";
import { sortAscendingByKey, multiFilter } from "../../helpers/sortFunctions";
import styled from "styled-components";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const SearchBar = styled.input`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 5px;
  outline: none;
`;

const AddItemToFamily = ({
  isChildItemRelation,
  item,
  reFetchItem,
  familyIds,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { response: items = [] } = useGet(
    API_URLS.ITEMSWITHCHILDREN,
    "",
    true,
    { "cache-control": "no-cache" }
  );
  const { doPatch } = usePatch(
    API_URLS.PATCHITEMFAMILY(item.itemTemplateId),
    accessToken
  );
  const [searchText, setSearchText] = useState("");

  const nonFamilyQuestions = items.filter((q) => {
    return (
      !familyIds.includes(q.itemTemplateId) &&
      (!isChildItemRelation ||
        (q.parentItemTemplateId === null && q.symptomTypeTemplates.length < 1))
    );
  });

  const filteredItems = multiFilter(nonFamilyQuestions, searchText, [
    "phrase",
    "itemTemplateId",
    "groupTitle",
  ]);
  const sortedItems = sortAscendingByKey(filteredItems, "phrase");

  const addItemHandler = (id, phrase, child = true) => {
    const data = child
      ? {
          childId: id,
        }
      : {
          parentId: id,
        };
    window.confirm(`Lägg till ${phrase} som ${child ? "barn" : "förälder"}?`) &&
      doPatch(data).then(() => reFetchItem());
  };

  return (
    <>
      <SearchBar
        placeholder={"Sök efter fras, grupp eller id..."}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Table>
        <tbody>
          {sortedItems.length < 1 && searchText.length > 0 && (
            <TableBodyRow>
              <Td>Inga matchande items</Td>
            </TableBodyRow>
          )}
          {(isChildItemRelation ||
            (sortedItems.length > 0 && searchText.length > 0)) &&
            sortedItems.map((q) => (
              <TableBodyRow
                onClick={() =>
                  addItemHandler(
                    q.itemTemplateId,
                    q.phrase,
                    isChildItemRelation
                  )
                }
                key={q.itemTemplateId}
              >
                <Td>Id:&nbsp;({q.itemTemplateId})</Td>
                <Td>{q.groupTitle}</Td>
                <Td>{q.phrase}</Td>
              </TableBodyRow>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default AddItemToFamily;
