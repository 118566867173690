import {Link} from "react-router-dom";
import styled from "styled-components";

const PageNumberLink = styled(Link)`
  border-radius: 50%;
  border: 1px solid ${({$isActive}) => $isActive ? "#FFE150": "#0A4A7C"};
  margin: 0 2px;
  text-decoration: none;
  color: #0A4A7C;
  background-color: ${({$isActive}) => $isActive ? "#FFE150": "#fff"};
  display: inline-block;
  line-height: 1.375rem;
  min-width: 2.5rem;
  text-align: center;
  padding: 0.5rem;
  

  :hover:not(.active) {
    border: 1px solid #0A4A7C;
    color: ${({$isActive}) => $isActive ? "#0A4A7C" : "#fff"};
    background-color: ${({$isActive}) => $isActive ? "#FFE150": "#0A4A7C"};
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
`


const Pagination = ({pageCount, currentPage}) => {
    return (
        <Nav>
            {[...Array(pageCount)].map((_, pageNumber) => (
                <PageNumberLink key={pageNumber} to={`?page=${pageNumber + 1}`} $isActive={pageNumber + 1 === currentPage}>
                    {pageNumber + 1}
                </PageNumberLink>
            ))}
        </Nav>
    );
};

export default Pagination;
