import { generatePath } from "react-router-dom";
import {
  Table,
  TableBodyRow,
  Td,
  H2,
  Input,
} from "@collabodoc/component-library";
import { VIEWS } from "../../URLS";
import { Col, Card } from "react-bootstrap";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledInput = styled(Input)`
  width: 50px;
  background-color: #fff;
  border: ${(props) => props.showError && "solid 2px red"};

  &:focus {
    border: ${(props) => props.showError && "solid 2px red"};
  }

  /*Remove arrows from number input*/
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-top: 10px;
`;

const StyledTd = styled(Td)`
  vertical-align: middle !important;
`;

const InputTd = styled(Td)`
  cursor: default;
`;

const getNumberOrNull = (elem) =>
  elem.value === "" ? null : elem.valueAsNumber;

const getValueOrEmptyString = (value) => (value == null ? "" : value);

const SymptomTypeQuestions = ({
  symptomtypeQuestions = [],
  handleRemoveQuestion,
  handleQuestionListChange,
}) => {
  const navigate = useNavigate();
  const sortedQuestions =
    symptomtypeQuestions &&
    symptomtypeQuestions.sort((a, b) => {
      if (a.groupIndex === b.groupIndex) {
        return a.index < b.index ? -1 : 1;
      } else {
        return a.groupIndex < b.groupIndex ? -1 : 1;
      }
    });

  return (
    <Col lg={{ span: 8 }}>
      <Card>
        <Card.Header>
          <H2>Frågor</H2>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Fras</th>
                <th>Sida</th>
                <th>Ordning</th>
                <th>Kolumn</th>
                <th>Journalindex</th>
              </tr>
            </thead>
            <tbody>
              {!sortedQuestions ? (
                <TableBodyRow>
                  <Td>Inga tillhörande frågor</Td>
                </TableBodyRow>
              ) : (
                sortedQuestions.map((question) => (
                  <TableBodyRow key={question.patientQuestionTemplateId}>
                    <Td
                      onClick={() =>
                        navigate(
                          generatePath(VIEWS.QUESTION, {
                            questionId: question.patientQuestionTemplateId,
                          })
                        )
                      }
                    >
                      {question.patientQuestionTemplateId}
                    </Td>
                    <Td
                      onClick={() =>
                        navigate(
                          generatePath(VIEWS.QUESTION, {
                            questionId: question.patientQuestionTemplateId,
                          })
                        )
                      }
                    >
                      {question.phrase}
                    </Td>
                    <InputTd title={"sida"}>
                      <StyledInput
                        type={"number"}
                        value={getValueOrEmptyString(question.groupIndex)}
                        showError={question.groupIndex == null}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleQuestionListChange(
                            question.patientQuestionTemplateId,
                            question.phrase,
                            getNumberOrNull(e.target),
                            question.index,
                            question.column,
                            question.journalSentenceIndex
                          )
                        }
                      />
                    </InputTd>
                    <InputTd title={"ordning"}>
                      <StyledInput
                        type={"number"}
                        value={getValueOrEmptyString(question.index)}
                        showError={question.index == null}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleQuestionListChange(
                            question.patientQuestionTemplateId,
                            question.phrase,
                            question.groupIndex,
                            getNumberOrNull(e.target),
                            question.column,
                            question.journalSentenceIndex
                          )
                        }
                      />
                    </InputTd>
                    <InputTd title={"kolumn"}>
                      <StyledInput
                        type={"number"}
                        value={getValueOrEmptyString(question.column)}
                        showError={question.column == null}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleQuestionListChange(
                            question.patientQuestionTemplateId,
                            question.phrase,
                            question.groupIndex,
                            question.index,
                            getNumberOrNull(e.target),
                            question.journalSentenceIndex
                          )
                        }
                      />
                    </InputTd>
                    <InputTd title={"journalindex"}>
                      <StyledInput
                        type={"number"}
                        value={getValueOrEmptyString(
                          question.journalSentenceIndex
                        )}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          handleQuestionListChange(
                            question.patientQuestionTemplateId,
                            question.phrase,
                            question.groupIndex,
                            question.index,
                            question.column,
                            getNumberOrNull(e.target)
                          )
                        }
                      />
                    </InputTd>
                    <StyledTd
                      onClick={(e) =>
                        handleRemoveQuestion(
                          e,
                          question.patientQuestionTemplateId
                        )
                      }
                    >
                      <StyledIcon icon={faTrashCan} />
                    </StyledTd>
                  </TableBodyRow>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SymptomTypeQuestions;
